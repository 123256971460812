.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
	background: transparent;
	transition: all .3s linear;
	font-family: $fontJ;
	font-weight: 700;
	@include media-breakpoint-down(sm) {
		background-color: cl(secondary);
	}
	&.header-scroll{
		box-shadow: rem(5px 0 10px rgba(0,0,0,0.2));
		background-color: cl(secondary);
		.header-logo{
			width: rem(160px);
			@include media-breakpoint-down(sm) {
				width: rem(120px);
			}
		}
		.header--inner-block{
			padding: rem(4px 0px 4px 0);
			@include media-breakpoint-down(lg) {
				padding: rem(5px 0px 5px 0px);

			}
		}
	}
	&.header--inner-pages{
		background-color: cl(secondary);
		.header-logo{
			width: rem(190px);
			@include media-breakpoint-down(sm) {
				width: rem(120px);
			}
		}
		.header--inner-block{
			padding: rem(4px 0px 4px 0);
			@include media-breakpoint-down(lg) {
				padding: rem(5px 0px 5px 0px);

			}
		}
	}
	.header--top-line{
		padding: 0.375rem 2.25rem;
		background-color: cl(black);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		color: cl(white);
		font-size: rem(18px);
		font-weight: 400;
		width: 100%;
		span{
			padding-right: 1rem;
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
		a{
			color:cl(white);
			i{
				animation: animation-pulse-1 4s infinite;
			}
		}
	}
	&-logo {
		width: rem(230px);
		flex-shrink: 0;
		transition: all 0.3s linear;
		@include media-breakpoint-down(sm) {
			width: rem(120px);
		}

		img {
			width: 100%;
			object-fit: contain;
			object-position:left center;
			transition: all 0.3s linear;
			@include media-breakpoint-down(lg) {
				width: 100%;
				height: auto;
			}
		}

	}
	&-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width:100%;
		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
			margin-bottom: 0;
		}
	}
	&-menu {
		position: fixed;
		right:rem(-500px);
		top:0;
		height: 100vh;
		margin: 0;
		width:rem(400px);
		padding: rem(155px 0 0);
		background: cl(complimentary);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		transition: all 0.3s linear;
		z-index: 10;
		@include media-breakpoint-down(lg) {
			width:rem(500px);
		}
		@include media-breakpoint-down(sm) {
			padding: rem(155px 0px 0px);
			justify-content:flex-start;
		}
		.header-buttons{
			display: none;
		}
		&.show {
			right:0;
			box-shadow: rem(5px 0 10px rgba(0,0,0,0.3));
			.header-buttons-mobile{
				display: flex;
			}
		}

		@include media-breakpoint-down(xs) {
			width:100%;
			right:-100%;
			padding-bottom: 0;
		}
		&-logo {
			width:rem(250px);
			position: absolute;
			top:rem(22px);
			@extend .pos-centerX;
		}
		ul {
		//	align-items: left;
			list-style: none;
			margin: 0;
			width:100%;
		//	overflow: auto;
			display: block;
			@include media-breakpoint-down(lg) {
				margin-bottom: 1rem;
			}
			& > li {
				position: relative;
			//	margin-left: rem(25px);
				padding: 0;
				&:before{
					display: none;
				}
				@include media-breakpoint-down(lg) {
					padding: 0;
					margin: 0;
					width:100%;

				}
				& > a {
					display: block;
					font-size: rem(16px);
					line-height: rem(26px);
					font-family: $fontJ;
					font-weight: 700;
					color:cl(white);
					@extend .ttu;
					position: relative;
					padding: 1rem 0rem;
					@include media-breakpoint-down(lg) {
						display: block;
						padding: 0.5rem 0;
					}
					&:hover {
						text-decoration: none;
						@include media-breakpoint-down(lg) {
							color: cl(primary);
						}
						&:before{
							width: 100%;
						}
					}
					i{
						font-size: rem(13px);
					}
					&:before{
						content: "";
						@extend .pos-centerX;
						bottom: -3px;
						width: 0;
						height: 3px;
						transition: all 0.3s linear;
						background-color: cl(white);
						@include media-breakpoint-down(lg) {
							display: none;
						}
					}

				}
				&.active {
					& > a {
						text-decoration: none;
						color:cl(primary);
						&:before{
							width: 100%;
							background-color: cl(primary);
							@include media-breakpoint-down(lg) {
								display: none;
							}
						}
					}
				}

				.drop {
					position: absolute;
					top:rem(10px);
					padding-top: rem(21px);
					left:unset;
					right: 0;
					width: max-content;
					min-width: rem(220px);
					display: none;
					@include media-breakpoint-down(lg) {
						//display: block;
						display: none;
						position: static;
						width:100%;
						min-width: 100%;
						padding: 0;
						margin: 0;
					}
					&:focus,
					&:visited{
						display: block;
					}
					> ul {
						margin: 0;
						background: cl(secondary);
						padding: rem(4px);
						list-style: none;
						box-shadow:rem(1px 1px 6px cl(black, .21));
						@include media-breakpoint-down(lg) {
							box-shadow: none;
							padding: 0;
							background: none;
						}
						> li {
							padding: 0;
							position: relative;
							&:before{
								display: none;
							}
							a {
								display: block;
								font-size: rem(16px);
								line-height: rem(26px);
								font-family: $font;
								color:cl(white);
								padding: rem(8px 20px 8px 20px);
								text-transform: none;
								@include media-breakpoint-down(lg) {
									display: block;
									padding: 1rem 2rem;
									font-size: rem(20px);
									line-height: rem(22px);
									//text-align: center;
									//	color:cl(primary);
									background: cl(secondary, 0.025);
									border-top: 1px solid cl(secondary, 0.75);
								}
								&:hover {
									text-decoration: none;
									background: cl(primary,0.7);
									color: cl(white);
									@include media-breakpoint-down(lg) {
										text-decoration: none;
										background: transparent;
										color: cl(primary);
									}
									.drop {
										display: block;
									}
								}
								&:focus {
									~ .drop {
										display: block;
									}
								}

							}
							&:visited {
								.drop {
									display: block;
								}
							}
							&:focus{
								outline: 1px dashed cl(primary);
								box-shadow: none;
							}
							.drop {
								position: absolute;
								top:0;
								padding-top: 0;
								left: rem(-220px);
								width: rem(220px);
								display: none;
								@include media-breakpoint-down(lg) {
									display: block;
									position: static;
									width:100%;
									min-width: 100%;
									padding: 0;
									margin: 0;
								}
								&.opened{
									display: block!important;
								}
								&:focus,
								&:visited{
									display: block;
								}
								ul {
									margin: 0;
									background: cl(white);
									padding: rem(2px);
									list-style: none;
									box-shadow:rem(1px 1px 6px cl(black, .21));
									@include media-breakpoint-down(lg) {
										box-shadow: none;
										padding: 0;
										background: none;
									}
									li {
										padding: 0;
										&:before{
											display: none;
										}
										a {
											display: block;
											font-size: rem(16px);
											line-height: rem(20px);
											font-weight: 400;
											font-family: $font;
											color:cl(primary);
											padding: rem(8px 20px 8px 20px);
											text-transform: none;
											@include media-breakpoint-down(lg) {
												display: block;
												padding: 1rem 2rem;
												font-size: rem(18px);
												line-height: rem(22px);
												text-align: center;
												color:cl(primary);
												background: cl(black, 0.1);
												border-top: 1px solid cl(white);
											}
											&:hover {
												text-decoration: none;
												background: cl(primary);
												color: cl(white);
												@include media-breakpoint-down(lg) {
													text-decoration: none;
													background: transparent;
													color: cl(secondary);
												}
											}
										}
										&.active {
											a {
												color: cl(white);
												text-decoration: none;
												background: cl(primary);
												@include media-breakpoint-down(lg) {
													text-decoration: none;
													background: cl(black, 0.1);
													color: cl(secondary);
												}
											}
										}
									}
								}
							}
							&.active {
								 a {
									color: cl(white);
									text-decoration: none;
									background: cl(primary);
									@include media-breakpoint-down(lg) {
										text-decoration: none;
										color:cl(primary);
										background: transparent;
										font-weight: 700;
									}
								}
							}
						}
					}

				}
			}
		}
		.menu-scroll{
			width: 100%;
			padding: rem(0px 38px 20px);
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include media-breakpoint-down(lg){
				display: block;
				overflow-y: auto;
				flex-grow: 1;
				max-height: 100%;
			}
		}
	}
	&-nav {
		width:100%;
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-right: 2.5rem;
		z-index: 2;
		@include media-breakpoint-down(lg) {
			position: fixed;
			z-index: 200;
			right:-100%;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			padding: rem(120px 0 0px 0);
			background: cl(white);
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			transition: all 0.3s linear;
			display: none;
			&.show {
				right:0;
				box-shadow: rem(5px 0 10px rgba(0,0,0,0.3));
			}
		}
		@include media-breakpoint-down(sm) {
			width:100%;
		}
		&-logo {
			display: none;
			position: absolute;
			top:1rem;
			@extend .pos-centerX;
			@include media-breakpoint-down(lg) {
				display: block;
				width: rem(290px);
			}
			@include media-breakpoint-down(sm) {
				display: block;
				width: 60vw
			}
		}
		& > ul {
			display: flex;
			align-items: center;
			list-style: none;
			margin: 0;
			@include media-breakpoint-down(lg) {
				height: 100%;
				width:100%;
				overflow: auto;
				display: block;
			}
			& > li {
				position: relative;
				margin-left: rem(25px);
				padding: 0;
				&:before{
					display: none;
				}
				@include media-breakpoint-down(lg) {
					padding: 0;
					margin: 0;
					width:100%;

				}
				& > a {
					display: block;
					font-size: rem(16px);
					line-height: rem(26px);
					font-family: $fontJ;
					font-weight: 700;
					color:cl(white);
					@extend .ttu;
					position: relative;
					@include media-breakpoint-down(lg) {
						display: block;
						padding: 1rem 2rem;
						font-size: rem(22px);
						line-height: rem(22px);
						text-align: center;
					}
					&:hover {
						text-decoration: none;
						@include media-breakpoint-down(lg) {
							color: cl(secondary);
						}
						&:before{
							width: 100%;
						}
					}
					i{
						font-size: rem(13px);
					}
					&:before{
						content: "";
						@extend .pos-centerX;
						bottom: -3px;
						width: 0;
						height: 3px;
						transition: all 0.3s linear;
						background-color: cl(white);
						@include media-breakpoint-down(lg) {
							display: none;
						}
					}

				}
				&.active {
					& > a {
						text-decoration: none;
						color:cl(primary);
						&:before{
							width: 100%;
							background-color: cl(primary);
							@include media-breakpoint-down(lg) {
								display: none;
							}
						}
					}
				}

				 .drop {
					position: absolute;
					top:rem(10px);
					padding-top: rem(21px);
					left:unset;
					 right: 0;
					width: max-content;
					min-width: rem(220px);
					display: none;
					@include media-breakpoint-down(lg) {
						//display: block;
						display: none;
						position: static;
						width:100%;
						min-width: 100%;
						padding: 0;
						margin: 0;
					}
					 &:focus,
					 &:visited{
						 display: block;
					 }
					> ul {
						margin: 0;
						background: cl(secondary);
						padding: rem(4px);
						list-style: none;
						box-shadow:rem(1px 1px 6px cl(black, .21));
						@include media-breakpoint-down(lg) {
							box-shadow: none;
							padding: 0;
							background: none;
						}
						> li {
							padding: 0;
							position: relative;
							&:before{
								display: none;
							}
							a {
								display: block;
								font-size: rem(16px);
								line-height: rem(26px);
								font-family: $font;
								color:cl(white);
								padding: rem(8px 20px 8px 20px);
								text-transform: none;
								@include media-breakpoint-down(lg) {
									display: block;
									padding: 1rem 2rem;
									font-size: rem(20px);
									line-height: rem(22px);
									text-align: center;
								//	color:cl(primary);
									background: cl(black, 0.05);
									border-top: 1px solid cl(white);
								}
								&:hover {
									text-decoration: none;
									background: cl(primary,0.2);
									color: cl(white);
									@include media-breakpoint-down(lg) {
										text-decoration: none;
										background: transparent;
										color: cl(secondary);
									}
									.drop {
										display: block;
									}
								}
								&:focus {
									~ .drop {
										display: block;
									}
								}

							}
							&:visited {
								.drop {
									display: block;
								}
							}
							&:focus{
								outline: 1px dashed cl(primary);
								box-shadow: none;
							}
							.drop {
								position: absolute;
								top:0;
								padding-top: 0;
								left: rem(-220px);
								width: rem(220px);
								display: none;
								@include media-breakpoint-down(lg) {
									display: block;
									position: static;
									width:100%;
									min-width: 100%;
									padding: 0;
									margin: 0;
								}
								&.opened{
									display: block!important;
								}
								&:focus,
								&:visited{
									display: block;
								}
								ul {
									margin: 0;
									background: cl(white);
									padding: rem(2px);
									list-style: none;
									box-shadow:rem(1px 1px 6px cl(black, .21));
									@include media-breakpoint-down(lg) {
										box-shadow: none;
										padding: 0;
										background: none;
									}
									li {
										padding: 0;
										&:before{
											display: none;
										}
										a {
											display: block;
											font-size: rem(16px);
											line-height: rem(20px);
											font-weight: 400;
											font-family: $font;
											color:cl(primary);
											padding: rem(8px 20px 8px 20px);
											text-transform: none;
											@include media-breakpoint-down(lg) {
												display: block;
												padding: 1rem 2rem;
												font-size: rem(18px);
												line-height: rem(22px);
												text-align: center;
												color:cl(primary);
												background: cl(black, 0.1);
												border-top: 1px solid cl(white);
											}
											&:hover {
												text-decoration: none;
												background: cl(primary,0.4);
												color: cl(white);
												@include media-breakpoint-down(lg) {
													text-decoration: none;
													background: transparent;
													color: cl(secondary);
												}
											}
										}
										&.active {
											a {
												color: cl(white);
												text-decoration: none;
												background: cl(primary);
												@include media-breakpoint-down(lg) {
													text-decoration: none;
													background: cl(black, 0.1);
													color: cl(secondary);
												}
											}
										}
									}
								}
							}
							&.active {
								> a {
									color: cl(white);
									text-decoration: none;
									background: cl(secondary);
									@include media-breakpoint-down(lg) {
										text-decoration: none;
										background: cl(black, 0.05);
										color: cl(secondary);
										font-weight: 700;
									}
								}
							}
						}
					}

				}
			}
		}
	}
	.container{
		width: calc(100vw - 2.25rem);
		max-width: 100%;
		z-index: 0;
		position: static;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
	&--inner-block{
		position: relative;
		z-index: 3;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: rem(8px 0px 8px 0);
		@include media-breakpoint-down(lg) {
			padding: rem(8px 0px 8px 0px);

		}
		.btn{
			@include media-breakpoint-down(xs) {
				line-height: rem(38px);
				font-size: rem(15px);
				letter-spacing: 0;
				padding: 0 0.5rem;
				min-width: unset;
			}
			@media (max-width: 375.98px) {
				line-height: 1.75rem;
				font-size: .65rem;
			}
		}
		&-top{
			position: relative;
			color: cl(primary);
			font-family: $font;
			font-weight: 400;
			display: flex;
			font-size: rem(15px);
			@extend .ttu;
			justify-content: flex-end;
			padding:rem(13px 0 18px 0);
			@include media-breakpoint-down(lg) {
				padding-bottom: 0;
				flex-grow: 1;
			}

			.colum{
				column-gap: 2rem;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				@include media-breakpoint-down(sm) {
					column-gap: 1rem;
				}

				&:first-child{
					padding: 0 2rem;
					color: cl(text);
					justify-content: center;
					width: 35rem;
					text-transform: none;
					@media (max-width: 1350.98px){
						width: 31rem;
					}
					@include media-breakpoint-down(lg) {
						width: 100vw;
						margin-bottom: 0.5rem;
						position: fixed;
						top: 0;
						left: 0;
						padding: 0 15px;

					}
				}
				&:last-child{
					//width: 46rem;
					display: flex;
					align-items: center;
					justify-content: flex-end;

				}
			}
			p{
				text-align: center;
				margin: 0 auto;
			}

			a{
				&:hover{
					text-decoration: none;
					color: cl(secondary);
				}
				i{

					font-size: rem(14px);
				}
			}

		}
		&-bottom{
			//margin-bottom: 0.5rem;
		}
	}
	.header-buttons{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		column-gap: 1rem;
		@include media-breakpoint-down(md) {
			justify-content: center;
			padding: 1rem 2rem;
			background-color: cl(white);
			width: 100%;
			column-gap: 3%;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
		}
		.btn{
			//line-height: rem(38px);
			@include media-breakpoint-down(md) {
				width: 47%;
			}
			@include media-breakpoint-down(xs) {
				width: 100%;
				margin-bottom: 1rem;
			}
		}


	}
	&-user{
		position: relative;
		margin-right: 1rem;
		&:hover {
			.drop{
				display: block;
			}
		}
		.user-link{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			cursor: pointer;
			flex-wrap: nowrap;
			font-weight: 700;
			color: cl(black);
			&:after{
				display: none;
			}
			&:focus{
				outline: none;
			}
			&:hover{
				text-decoration: none;
			}
			.avatar{
				width:rem(40px);
				height: rem(40px);
				border-radius: 50%;
				background: center/contain no-repeat url('../img/svg/avatar-3.svg'),cl(black);
				display: flex;
				justify-content: center;
				align-items: center;
				color: cl(white);
				flex-shrink: 0;
				@extend .ttu;
				font-size:rem(14px);
				font-style: normal;
				margin-right: 0.5rem;
				@include media-breakpoint-down(sm) {
					margin-right: 0rem;
				}
				@include media-breakpoint-down(xs) {
					width:rem(28px);
					height: rem(28px);
				}
			}

		}

		.dropdown-menu {
			padding: 0;
			border: 0;
			border-radius: 0;
			box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.2);
			background-color: cl(white);
			margin-top: 1rem;
			.dropdown-item{
				color: cl(black);
				font-size:rem(18px);
				padding: 1rem 1rem;
				display: flex;
				align-items: center;
				&:active {
					text-decoration: none;
					color: cl(white);
					background-color: cl(primary);
				}
				&:hover{
					text-decoration: none;
					color: cl(white);
					background-color: cl(primary);
					.item-link{
						color: cl(white);
					}
					.notifications-info-link{
						color: cl(white);
					}
					i{
						color: cl(white);
					}
				}
				.notifications-info-link{
					width: 1.55rem;
					color: cl(black);
				}
				img{
					width: 1.25rem;
					vertical-align: middle;
				}
				i{
					color:cl(black);
				}
				.item-link{
					display: flex;
					align-items: center;
					color: cl(black);
					justify-content: flex-start;
				}
			}
		}
	}
}
/*hamburger btn*/
.btn-lines {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	height:rem(2px);
	background-color:cl(white);
	content: "";
	transition: all 0.3s ease;
}
.c-hamburger {
	margin-left: 2rem;
	position: relative;
	z-index: 2;
	padding: 0;
	height:rem(20px);
	width:rem(26px);
	flex-shrink: 0;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	border: none;
	cursor: pointer;
	transition: all 0.3s ease  0.3s;
	&:focus {
		outline: none;
	}

	span {
		display: block;
		position: absolute;
		top:rem(9px);
		left: 0;
		right: 0;
		height:rem(2px);
		background: cl(white);
		transition: all 0.3s ease;
	}
	span::before {
		@extend .btn-lines;
		top:rem(-7px);
	}
	span::after {
		@extend .btn-lines;
		bottom:rem(-7px);
		width: 70%;
		left: 30%;
	}
	&--htx {
		background-color: transparent;
		&.active {
			&:before {
				display: none;
			}
			span {
				background: none;
			}
			span::before {
				top: 0;
				transform:rotate(45deg);
				background: cl(white);
			}
			span::after {
				bottom: 0;
				transform:rotate(-45deg);
				background: cl(white);
				width: 100%;
				left: 0;
			}
		}
	}
}
.close-nav-btn{
	position: absolute;
	top: 1.5rem;
	right: 1rem;
}
/*hamburger btn*/




@include media-breakpoint-up(lg) {
	.header-nav ul li:hover > .drop {
		display: block!important;
	}
	.header-nav>ul>li>a ~ .drop:focus-within {
		display: block!important;
	}
	.header-menu>ul>li>a ~ .drop:focus-within {
		display: block!important;
	}
	//.header-menu:focus-within {
	//	//display: block!important;
	//	right: 0;
	//}
}

.header-nav>ul>li .drop>ul>li:hover > .drop{
	display: block;
	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.header-nav>ul>li .drop>ul>li:hover {
	background-color: cl(primary);
	@include media-breakpoint-down(lg){
		background-color: transparent;
		color: cl(secondary);
		a {
			color: cl(secondary);
		}
	}
	a {
		color: cl(white);
		@include media-breakpoint-down(lg){
			color: cl(secondary);
		}
	}
}

a{
	&:focus{
		outline: 1px dashed cl(primary);
		box-shadow: none;
	}
}
