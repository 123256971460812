.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: cl(text);
	min-height: 100vh!important;
	background: cl(white);
	font-family: $font;
	line-height: 1.5;
	overflow-x: hidden;
	@include rem-baseline;
	font-size: calc(10.75px + 15 * ((100vw - 1200px) / 2048));
	@media screen and (min-width: 2049px) {
		font-size: calc(20px + 15 * ((100vw - 2049px) / 4096));
	}
	@include media-breakpoint-down(lg) {
		font-size: calc(12px + 4 * ((100vw - 576px) / 1199.98));
	}
	@include media-breakpoint-down(md) {
		font-size: calc(12px + 4 * ((100vw - 576px) / 1199.98));
	}
	@include media-breakpoint-down(xs) {
		font-size: calc(13px + 2 * ((100vw - 320px) / 575.98));
	}
	main{
		flex-grow: 1;
		min-height: calc(100vh - 40.5rem);
		@include media-breakpoint-down(sm) {
			min-height: calc(100vh - 53.5rem);
		}
		@include media-breakpoint-down(xs) {
			min-height: calc(100vh - 63.5rem);
		}
	}
	&.modal-open{
		padding: 0!important;
	}
}
img {
	vertical-align: top;
}
a {
	color: cl(primary);
	text-decoration: none;
	&:hover {
		color: cl(primary);
		text-decoration: underline;
	}
}
.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.container {
	--size: 97.1875rem;
	max-width: var(--size);
	position: relative;
	z-index: 2;
	@include media-breakpoint-down(sm) {
		max-width: 100%;
	}
	.locationscrollitem_container__2adeD:hover .locationscrollitem_name__2vuEF {
		-webkit-text-fill-color: #fff;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: #191414;
	}
	&--small {
		max-width: rem(1160px);
		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}
	}
	&--big{
		max-width: calc(100vw - 4.5rem);
		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}
	}
	&--xs{
		max-width: rem(930px);
		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}
	}
}
.wrapper {
	padding: rem(0 190px);
}
.loader {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	background: cl(white);
}

.italic-ttl{
	font-size: rem(20px);
	line-height: 1.2;
	margin: rem(0 0 20px);
	font-family: $fontP;
}
h2 {
	font-size: rem(30px);
	line-height: 1.2;
	margin: rem(0 0 20px);
	font-family: $fontJ;
	font-weight: 700;
	@include media-breakpoint-down(xs) {
		font-size: rem(25px);
	}
}

h3 {
	font-size: rem(36px);
	line-height: 1.4;
	margin: rem(0 0 20px);
	font-family: $font;
	font-weight: 700;
	@include media-breakpoint-down(xs) {
		font-size: rem(20px);
	}
}
h4 {
	font-size: rem(30px);
	line-height: rem(30px);
	margin: rem(0 0 10px);
	font-family: $font;
	font-weight: 700;
	@include media-breakpoint-down(xs) {
		font-size: rem(18px);
	}
}
h5 {
	font-size: rem(26px);
	line-height: rem(25px);
	margin: rem(0 0 10px);
	font-family: $font;
	font-weight: 700;
	@include media-breakpoint-down(xs) {
		font-size: rem(18px);
	}
}
h6 {
	font-size: rem(22px);
	line-height: rem(25px);
	margin: rem(0 0 10px);
	font-family: $font;
	font-weight: 700;
	@include media-breakpoint-down(xs) {
		font-size: rem(18px);
	}
}

body .uwy.userway_p1 .uai {
	top: 87% !important;
	background: cl(primary,0.5) !important;
}
.userway-s4-1-ff,
.userway-s4-2-ff,
.userway-s4-3-ff,
.userway-s4-4-ff{
	line-height: 1.5;
	p,div,span,a{
		line-height: 1.5;
		hyphens: auto;
	}
	body{
		line-height: 1.5;
	}
	.header.header--inner-pages .header--inner-block-bottom {
		flex-wrap: wrap;
	}
	.header {
		&.header-scroll {
			position: absolute;
		}
	}
	.footer--top-line-inner{
		flex-wrap: wrap;
	}
	.header-nav>ul>li .drop ul li a {
		line-height: 1.5;
	}
}


.breadcrumb {
	background: none;
	padding: rem(26px 0 5px 0);
	color:cl(secondary);
	margin: 0;
	&-item {
		color:cl(secondary);
		font-size: rem(13px);
		&:before {
			color:cl(secondary)!important;
		}
		&.active {
			color:cl(primary);
		}
		a {
			color:cl(secondary);
			&:hover {
				text-decoration: none;
				color:cl(primary);
			}
		}
	}
}

div,p,span,h2,h3,h4,h5,h6,a,button{
	&:focus{
		outline: 1px dashed cl(primary);
	}
}

section{
	&:focus{
		outline: none
	}
}
