.login-page{
  height: 100vh;
  position: relative;
  display: flex;
//  min-height: rem(700px);
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 3rem;
    height: unset;
  }
  .copy{
    font-size: rem(13px);
    position: absolute;
    left: rem(40px);
    color: #6f6f6f;
    bottom: rem(25px);
    z-index: 5;
    @include media-breakpoint-down(md) {
      position: relative;
      left: unset;
      bottom: unset;
      margin-left: 2rem;
    }
  }
  .image-side {
    width:50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(md) {
    //  height: 30rem;
      width:100%;
      padding: rem(120px 0 50px 0);
    }
    .deco{
      opacity: 0.2;
      position: absolute;
      width: rem(165px);
      right: rem(45px);
      bottom: rem(45px);
    }

    .video-holder{
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      width: 100%;
      box-shadow: -6.81px 13.365px 50px 0px rgba(0, 0, 0, 0.33);
      background-color: cl(black);
      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      video{
        width: 100%;
        object-fit: contain;
        object-position: center;

      }
    }
    iframe{
      width: 100%;
    }
    .container{
      max-width: rem(700px);
    }
    .decor {
      position: absolute;
      left:0;
      bottom: 0;
      width:100%;
      height: auto;
      z-index: 3;
    }
  }
  .content-side {
    width:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: rem(16px);
    padding:rem(85px 0px 85px 0);
    @include media-breakpoint-down(md) {
      width:100%;
    }
    .form-holder {
     width: rem(530px);
      margin: 0 auto;
      @include media-breakpoint-down(xs) {
        width:100%;
        padding:rem(25px);
      }
      &.form-holder-small{
        width: rem(350px);
        margin: 0 auto;
        @include media-breakpoint-down(xs) {
          width:100%;
          padding:rem(25px);
        }
      }
      .form-group{
        margin-bottom: rem(40px);
      }
      .forgot {
        font-size: rem(15px);
        @extend .inline-b;
        color:cl(black);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }

    }
    .btn{
      width: 100%;
    }
    .bottom-side {
      position: absolute;
      bottom: 0;
      left:0;
      width:100%;
      text-align: center;
      color:#333333;
      height:rem(85px);
      a {
        color:#333333;
        &:hover {
          text-decoration: none;
          color:cl(secondary);
        }
      }
    }
  }
}