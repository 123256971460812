.injury-testimonials-section{
  .letter-decor{
    font-size: 8.5vw;
    color: cl(white);
    line-height: 14vw;
    font-family: $fontJ;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
    letter-spacing: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 6vw;
    overflow: hidden;
    z-index: 0;
    //margin-bottom: -3.5vw;
    // margin-top: -3vw;
    //@include media-breakpoint-down(md) {
    //  font-size: 14.4vw;
    //  line-height: 14.4vw;
    //}

    .word{
      display: flex;
    }
  }
  padding: rem(70px 0 0 0);
  .ttl-wrap{
    position: relative;
    margin: rem(0 0 20px);
    text-align: center;
    padding-bottom: rem(22px);

    h2{
      @extend .ttu;
    }
    &:before {
      content: "";
      position: absolute;
      width: rem(60px);
      height: rem(4px);
      font-style: normal;
      bottom: 0;
      background-color: cl(primary);
      animation: animation-line-2 6s infinite linear;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .sub-ttl{
    color: #545454;
    font-size: rem(20px);
    line-height: rem(30px);
    font-family: $fontP;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 0;
  }
  .text-side{
    color: cl(black);
    font-size: rem(16px);
    line-height: 1.5;
    padding-right: 2rem;
    font-family: $font;
    @include media-breakpoint-down(md) {
      padding-right: 0;
    }
    a{
      color: inherit;
    }
    p{
      margin-bottom: 2rem;
    }
    .ttl-wrap{
      position: relative;
      margin: rem(0 0 20px);
      text-align: left;
      padding-bottom: rem(22px);
      &:before {
        content: "";
        position: absolute;
        width: rem(60px);
        height: rem(4px);
        font-style: normal;
        bottom: 0;
        background-color: cl(primary);
        animation: none;
        left: 0%;
        transform: translateX(0);
      }
      .sub-ttl{
        text-align: left;
        margin-bottom: 1rem;
      }
    }

  }
  .image-side{
    margin-left: 1vw;
    width: 46.19vw;

    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-left: 0;
    }
    img{
      width: 100%;
      object-fit: cover;
      max-height: 50vh;
      box-shadow: rem(0px 17px 70px 0px rgba(0, 0, 0, 0.3));
    }
  }
  .testimonials-injury-slider{
    margin-bottom:rem(70px);
    @include media-breakpoint-down(sm) {
      padding-bottom: 3rem;
      margin-bottom:rem(30px);
    }
    .item{
      padding: rem(1px 30px 1px 1px);
      @include media-breakpoint-down(md) {
        padding: 1rem 5rem;
      }
      @include media-breakpoint-down(sm) {
        padding: 1rem 1rem;
      }
      .item-inner{
        margin: 0 auto;
        text-align: left;
        color: cl(black);
        font-size: rem(20px);
        font-family: $fontP;
        line-height: rem(40px);
        .signature{
          font-family: $fontJ;
          font-weight: 700;
        }
        .line{
          width: rem(60px);
          height: rem(4px);
          background-color: cl(primary);
          margin-bottom: rem(24px);
        }
        .cont{
          margin-bottom: rem(40px);
          p{
            margin-bottom: 0;
          }
        }
        img{
          height: rem(45px);
          margin-bottom: rem(15px);
        }
      }
    }
  }
}
.injury-info-section{
  padding: rem(64px 0 20px 0);
  background: center/cover no-repaet;
  background-attachment: fixed;
  font-size: rem(16px);
  line-height: rem(26px);
  p{
    margin-bottom: 1.5rem;
  }
  .text-information-block {
    .sub-ttl {
      color: cl(black);
      font-size: rem(20px);
      line-height: rem(30px);
      font-family: $fontP;
      letter-spacing: 0.05em;
      margin-bottom: 1rem;
      text-align: center;
    }
    h2 {
      position: relative;
      padding-bottom: rem(20px);
      color: cl(black);
      @extend .ttu;
      margin-bottom: 2rem;
      text-align: center;

      &:before {
        content: "";
        position: absolute;
        width: rem(60px);
        height: rem(4px);
        left: 50%;
        font-style: normal;
        transform: translateX(-50%);
        bottom: 0;
        background-color: cl(primary);
        animation: animation-line-2 6s infinite linear;
      }
    }
  }
  .accordion-information-block{
    padding: rem(45px 0 10px 0);
    h2 {
      position: relative;
      padding-bottom: rem(20px);
      color: cl(black);
      @extend .ttu;
      margin-bottom: 2rem;
      text-align: center;

    }
    .faq-accordion{
      z-index: 5;
      position: relative;
    }
    .card {
      background: none;
      position: relative;
      margin: rem(0 0 10px);
      font-family: $fontJ;
      border: 0;
      border-radius: 0;
      .card-header {
        background: none;
        padding: 0;
        border-bottom: 0;
        box-shadow: 0.968px 3.881px 10px 0px rgba(0, 0, 0, 0.15);
        .head-link {
          border: 0;
          &.collapsed {
            border: 0;
          }
        }
        button {
          font-size: rem(20px);
          line-height: rem(30px);
          margin: rem(0 0 0px);
          font-family: $fontJ;
          font-weight: 700;
          color:cl(black);
          @extend .ttu;
          background: cl(primary);
          width:100%;
          display: block;
          border:0;
          padding: rem(15px 40px 15px 30px);
          position: relative;
          text-align: left;
          border-radius: rem(0px);
          @include media-breakpoint-down(sm) {
            font-size: rem(20px);
            line-height: rem(26px);
          }
          @include media-breakpoint-down(xs) {
            font-size: rem(18px);
            line-height: rem(26px);
          }
          &:focus {
            outline: 1px dashed cl(secondary);
          }
          &.collapsed {
            background: cl(white);
            .plus-minus{
              &:after{
                opacity: 1;
              }
              &:before{
                background-color: cl(primary);
              }
            }
          }
          @include media-breakpoint-down(xs) {
            font-size: rem(20px);
            line-height: rem(30px);
          }
          .plus-minus{
            position: absolute;
            right:rem(30px);
            top: 50%;
            transform: translateY(-50%) rotate(-180deg);
            transition: all .3s ease;
            width: rem(26px);
            height: rem(26px);
            @include media-breakpoint-down(xs) {
              width: rem(20px);
              height: rem(20px);
              right:rem(20px);
            }
            &:before{
              content: "";
              position: absolute;
              width: 100%;
              height: rem(2px);
              @extend .pos-centerY;
              left: 0;
              background-color: cl(black);
            }
            &:after{
              content: "";
              position: absolute;
              height: 100%;
              width: rem(2px);
              @extend .pos-centerX;
              background-color: cl(primary);
              top: 0;
              opacity: 0;
              transition: 0.3s ease-in-out;
            }
          }
        }
        .logo{
          position: absolute;
          left:rem(20px);
          @extend .pos-centerY;
          transition: all .3s ease;
          z-index: 2;
          width:rem(30px);
          height: rem(30px);
          object-fit: cover;
          border-radius: 50%;
          @include media-breakpoint-down(xs) {
            width:rem(30px);
            height: rem(30px);
          }
        }
      }
      .collapse-body {
        border: 0;
        border-radius:rem(0px);
      }
      .card-body {
        background-color: transparent;
        padding: rem(25px 0px 0px 0px);
        font-size: rem(16px);
        line-height: rem(26px);
        border:0;
        border-radius: 0;
        font-family: $font;
        color: cl(black);
        p {
          margin: 0 0 1.5rem;
        }
        &:focus {
          outline: none;
        }
        div{
          &:focus {
            outline: none;
          }
        }
        ul,ol{
          padding-left: 2rem;
        }

      }
    }
  }

}