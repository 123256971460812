.footer {
  background:#202020;
  position: relative;
  z-index: 2;
  color:#c6c6c6;
  font-size: rem(15px);
  line-height: rem(25px);
  a{
    color: #c6c6c6;
  }
  h6{
    font-size: rem(15px);
    font-weight: 700;
    color: cl(white);
    font-family: $fontJ;
    margin-bottom: 1.5rem;
  }
  .column{
    border-left: 1px solid rgb(54, 54, 54);
    margin-bottom: 2rem;
    &:first-child{
      border: 0;
    }
    @include media-breakpoint-down(xs) {
      border: 0;
    }
  }
  .call{
    font-size: rem(15px);
    font-family: $fontJ;
    font-weight: 700;
    a{
      font-size: rem(22px);
      display: block;
      color: cl(primary);
      line-height: rem(36px);

      i{
        font-size: rem(16px);
        margin-right: 0.25rem;
        animation: animation-pulse-1 4s infinite;
      }
    }
  }
  &-copy {
    text-align: center;
    font-size: rem(14px);
    font-family:$font;
    color: rgb(141, 141, 141);
    @include media-breakpoint-down(xs) {
      text-align: center;
      margin: 0 auto;
      display: block;
    }
  }

  &--top-line{
    padding: rem(80px 0 10px 0);
  }
  .social{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0 1.5rem;
    margin-top: 1.5rem;
    a{
      color: cl(primary);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(20px);
      &:hover{
        text-decoration: none;
        color: cl(white);
      }
    }
  }
  &--bottom-line{
    padding: rem(6px 0);
    background:#181818;
    color: cl(white);
    text-align: center;
  }
  ul {
    list-style: none;
    margin: 0;
    & > li {
      position: relative;
      border-bottom: 1px solid rgb(54, 54, 54);
      padding: rem(12px 0 12px 24px);
      &:before{
        content: '\f054';
        position: absolute;
        top:rem(14px);
        left: 0;
        line-height: rem(22px);
        text-align: center;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: rem(12px);
      }
      & > a {
        display: block;
        position: relative;
        &:hover {
          text-decoration: none;
          color: cl(primary);
        }
        i{
          font-size: rem(13px);
        }
      }
      &.active {
        & > a {
          text-decoration: none;
          color:cl(primary);
        }
      }
    }
  }
  .up-link{
    border-radius: 50%;
    background-color: rgb(198, 198, 198);
    box-shadow: 0px 9px 40px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    width: rem(40px);
    height: rem(40px);
    bottom: 1rem;
    right: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: cl(secondary);
  }
}
