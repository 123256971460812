.affiliates-inner-block{
  padding: rem(55px 0 40px 0);
  h2{
    text-align: center;
  }
  &-grid{
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;
    flex-wrap: wrap;
    padding-bottom: rem(27px);
    .affiliate-item{
      background-color: rgb(255, 255, 255);
      box-shadow: -6.553px 4.589px 29px 0px rgba(0, 0, 0, 0.16);
      padding: rem(45px 40px);
      width: calc(33.3% - 1.45rem);
      @include media-breakpoint-down(sm){
        width: calc(50% - 1.45rem);
      }
      @include media-breakpoint-down(xs){
        width:100%;
      }
      img{
        max-width: 100%;
        max-height: rem(300px);
        object-fit: contain;
      }
    }
  }
}
.link-back{
  font-weight: 700;
  @extend .ttu;
  color: cl(black);
  font-size: rem(16px);
  i{
    font-size: rem(14px);
  }
}
.affiliates-main{
  padding: rem(55px 0 40px 0);
  ul{
    max-width: rem(860px);
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    li{
      width: 48%;
      position: relative;
      padding:rem(7px 0 7px 35px);
      font-size: rem(16px);
      line-height: 1.5;
      @include media-breakpoint-down(sm){
        width:100%;
      }
      a{
        color: cl(black);
      }
      &:before{
        content: "";
        position: absolute;
        left: 0;
        border-radius: 50%;
        background-color: cl(black);
        width: rem(4px);
        height: rem(4px);
        top: rem(17px);

      }
    }
  }
  .more--professions-block{
    padding: rem(15px 40px);
    background-color: cl(black);
    color: cl(white);
    text-align: center;
    margin: 2.5rem auto 0;
    @extend .ttu;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    width: max-content;
    max-width: rem(450px);
    @include media-breakpoint-down(xs){
      width:100%;
      max-width: 100%;
    }
    p{
      margin-bottom: 0;
    }
    a{
      font-weight: 400;
      color: cl(white);
      text-decoration: underline;
      &:hover{
        color: cl(primary);
      }
    }
  }
}
.affiliate-inner-inside{
  padding: rem(55px 0 40px 0);
  h2,h3{
    text-align: center;
  }
  .affiliate-inner-inside-wrap{
    margin: 0 auto;
    width: rem(830px);
    max-width: 100%;
  }
  .affiliate-inner-inside-item {
    background-color: rgb(255, 255, 255);
    box-shadow: -2.924px 12.667px 24px 0px rgba(0, 0, 0, 0.23);
    margin-bottom: 1.5rem;
    position: relative;
    font-size: rem(14px);
    line-height: 1.5;
    padding: rem(27px);
    @include media-breakpoint-down(xs) {
      font-size: rem(16px);
    }
    .ttl{
      background-color: #f3f3f3;
      color: cl(black);
      font-size: rem(20px);
      line-height: 1.5;
      font-weight: 700;
      padding: rem(5px 25px);
    }
    .offset{
      padding: rem(22px 25px);
    }
    .top-line{
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-bottom: rem(40px);
      &-text{
        @include media-breakpoint-down(xs){
          width:100%;
        }
      }
      img{
        height: rem(100px);
        max-width: 50%;
        object-fit: contain;
        flex-shrink: 0;
        margin-right: 2rem;
        @include media-breakpoint-down(xs){
          margin-right:0;
          margin-bottom: 1rem;
          max-width: 100%;
        }
      }
      .name{
        font-size: rem(24px);
        font-weight: 700;
      }
      .since{
        font-size: rem(13px);
        color: #4e4e4e;
      }
      .contact-name{
       margin-top: 1.2rem;
      }
    }
    .line{
      width: 1px;
      height: 100%;
      position: absolute;
      left: 0;
      background-color: rgb(221, 221, 221);
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    address{
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }
    .contacts {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: rem(14px);
      line-height: rem(25px);
      margin-bottom: 0;

      dt {
        color:cl(black);
        font-size: rem(16px);
        line-height: rem(26px);
        width:rem(30px);
      }
      dd {
        margin: 0;
        a {
          color:cl(black);
        }
      }
    }
  }

}