.modal{
  background-color: cl(black,0.8);
  padding: 0!important;
  .modal-dialog {
    max-width: rem(600px);
    @include media-breakpoint-down(xs) {
      max-width: 90%;
      margin: 0 auto;
    }
  }
  .modal-header,
  .modal-footer{
    border: 0;
  }
  .modal-content {
    border: 0;
    border-radius: 0;
    position: relative;
    padding: 0;
    box-shadow: -5.209px 29.544px 68px 0px rgba(0, 0, 0, 0.48);
  }
  .close {
    margin: 0;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    width: rem(18px);
    height: rem(18px);
    overflow: hidden;
    text-indent: -999px;
    background: url(../img/close.png) no-repeat 50% 50%;
    background-size: contain;
    padding: 0;
    opacity: 1;
    z-index: 10;
  }
  h2{
    font-size: rem(24px);
    line-height: rem(34px);
    margin: rem(0 0 0px);
    font-family: $font;
    font-weight: 700;
    text-align: center;
    text-transform: none;
    @include media-breakpoint-down(xs) {
      font-size: rem(22px);
      line-height: rem(32px);
    }
  }
  &.modal-info{
    .modal-body{
      padding: rem(40px 70px);
      font-size: rem(16px);
      font-weight: 400;
      line-height: 1.6;
      text-align: center;
      @include media-breakpoint-down(sm) {
        padding: rem(40px 30px);
      }
    }
  }
}