.home--welcome-section{
  padding: rem(75px 0 66px 0);
  background-color: cl(white);
  position: relative;
  z-index: 3;
  .letter-decor{
    font-size: 10.4vw;
    color: cl(white);
    line-height: 14vw;
    font-family: $fontJ;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
    letter-spacing: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    overflow: hidden;
    z-index: 0;
    margin-bottom: -3.5vw;
    margin-top: -3vw;
    //@include media-breakpoint-down(md) {
    //  font-size: 14.4vw;
    //  line-height: 14.4vw;
    //}

    .word{
      display: flex;
    }
  }
  .slogan{
    font-size: 10.4vw;
    color: cl(white);
    line-height: 1.5;
    font-family: $fontJ;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
    letter-spacing: 0.3em;
    margin-bottom: -2vw;

  }
  .welcome-cards-grid{
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap:0 2.5rem;
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    .welcome-card{
      position: relative;
      width: calc(50% - 2rem);
      box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.3);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-attachment: fixed;
      padding: rem(50px 100px);
      @include media-breakpoint-down(md) {
        background-attachment: scroll;
        padding: rem(50px 50px);
      }
      @include media-breakpoint-down(sm) {
        width: 100%;

      }
      @include media-breakpoint-down(xs) {
        padding: rem(35px 30px);
      }
      &:after{
        content: "";
        @extend .full;
        background-color: rgba(164, 190, 74, 0.88);
        top: 0;
        left: 0;
      }
      &:last-child{
        &:after{
        content: "";
        top: 0;
        left: 0;
        @extend .full;
        background-color: cl(black);
        }
        .welcome-card-inner{
          color: #c6c6c6;
        }
        p{
          max-width: rem(570px);
          margin: 0 auto;
        }
      }
      .welcome-card-inner{
        position: relative;
        z-index: 3;
        text-align: center;
        color: cl(white);
        font-size: rem(15px);
        line-height: rem(25px);
        .first-line{
          color: cl(white);
          font-size: rem(20px);
          line-height: rem(30px);
          font-family: $fontP;
          letter-spacing: 0.05em;
          margin-bottom: rem(14px);
        }
        .ttl{
          color: cl(white);
          font-size: rem(30px);
          line-height: rem(40px);
          font-family: $fontJ;
          font-weight: 700;
          @extend .ttu;
          margin-bottom: rem(18px);
          a{
            color: cl(white);
          }
        }
        .line{
          margin: 0 auto 2rem;
          width: rem(60px);
          height: rem(5px);
          background-color: cl(white);
          &-green{
            background-color:cl(primary);
        }
        }
        .tel-img{
          height: rem(30px);
          margin-bottom: rem(14px);
          animation: animation-pulse-1 4s infinite;
        }
        .call-link{
          color: cl(primary);
          font-size: rem(15px);
          line-height: rem(40px);
          font-family: $fontJ;
          font-weight: 700;
          @extend .ttu;
        }
      }

    }
  }
}
.home--services-section{
  padding: rem(20px 0 50px 0);
  background-color: cl(white);
  position: relative;
  z-index: 2;
  h2{
    position: relative;
    padding-bottom: rem(40px);
    &:before{
    content: "";
    position: absolute;
    width: rem(60px);
    height: rem(4px);
    left:50%;
    font-style: normal;
    transform: translateX(-50%);
    bottom: 0;
    background-color: cl(primary);
    animation: animation-line-2 6s infinite linear;
    }
  }
  .marque-wrap{
    margin-top: -4.5vw;
    margin-bottom: -2vw;
    overflow: hidden;
    .marquee-honor{
      b{
        padding-right: 10vw;
        font-size: 10vw;
        color: cl(white);
        line-height: 13vw;
        font-family: $fontJ;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
        vertical-align: middle;
        letter-spacing: 0.3em;
        img{
          padding-right: 2vw;
          height: 12vw;
          vertical-align: sub;
        }
      }
    }
  }
  .services-grid{
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap:2rem 2.5rem;
    position: relative;
    z-index: 2;
    width: 100%;
    flex-wrap: wrap;
    @include media-breakpoint-down(xs) {
      gap:2rem 2.5rem;
    }
    .service-card{

      width: calc(25% - 1.95rem);
      @include media-breakpoint-down(md) {
        width: calc(50% - 1.65rem);
      }
      @include media-breakpoint-down(xs) {
       width: 100%;
      }
      //&:nth-child(even){
      //  padding-top: (80px);
      //  @include media-breakpoint-down(md) {
      //    padding-top: (40px);
      //  }
      //  @include media-breakpoint-down(xs) {
      //    padding-top: 0;
      //  }
      //}
      .service-card-inner{
        background: center/cover no-repeat, cl(black);
        position: relative;
        min-height: 20.5vw;
        padding:rem(70px 20px);
        box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.3);
        text-align: center;
        &:hover,
        &:focus-within{
          &:after{
            background-color: cl(black,0.83);
          }
          .main-text{
            opacity: 1;
            z-index: 4;
          }
          .name-text{
            opacity: 0;
            z-index: 1;
          }
        }
        &:after{
          content: "";
          @extend .full;
          background-color: cl(black,0.4);
          top: 0;
          left: 0;
          transition: 0.3s all ease-in-out;
          z-index: 1;
        }
        .main-text{
          position: relative;
          z-index: 1;
          color: cl(white);
          font-size: rem(15px);
          line-height: rem(25px);
          font-family: $font;
          transition: 0.4s all ease-in-out;
          opacity: 0;
          .more-link{
            display: inline-block;
            margin-top: 2rem;
            color: cl(primary);
            font-size: rem(15px);
            line-height: rem(40px);
            font-family: $fontJ;
            font-weight: 700;
            @extend .ttu;
          }
        }
        .name-text{
          position: absolute;
          color: cl(white);
          font-size: rem(24px);
          line-height: rem(28px);
          font-family: $fontJ;
          font-weight: 700;
          transition: 0.4s all ease-in-out;
          opacity: 1;
          z-index: 4;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @extend .ttu;
          @extend .pos-center;
          width: 100%;
          height: 100%;
          padding: 2rem;
          img{
            margin-bottom: 1rem;
            height: rem(38px);
          }
        }
      }
    }
  }
}

.testimonials-section{
  padding: rem(100px 0 0 0);
  background-color: #272727;
  position: relative;
  overflow: hidden;
  z-index: 1;
  .slogan{
    font-size: 10.4vw;
    color: cl(white);
    line-height: 1;
    font-family: $fontJ;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
    letter-spacing: 0.3em;
    margin-bottom: -2vw;

  }
  .quote{
    width: 2rem;
    margin-bottom: 1.25rem;
  }
  .letter-decor{
    margin-top: -2vw;
    margin-bottom: -1vw;
    font-family: $fontJ;
    font-size: 8.3vw;
    line-height: 8.3vw;
    position: relative;
    letter-spacing: 0.3em;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    color: rgba(255, 255, 255, 0);
    text-stroke: 1px cl(white,0.1);
    -webkit-text-fill-color: rgba(255, 255, 255, 0); /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: cl(white,0.1);
    overflow: hidden;
    z-index: 0;
    //@include media-breakpoint-down(md) {
    //  font-size: 14.4vw;
    //  line-height: 14.4vw;
    //}

    .word{
      display: flex;
    }
  }
  h2{
    position: relative;
    color: cl(white);
    text-align: center;
    padding-bottom: rem(30px);
    &:before{
      content: "";
      position: absolute;
      width: rem(60px);
      height: rem(4px);
      left:50%;
      font-style: normal;
      transform: translateX(-50%);
      bottom: 0;
      background-color: cl(primary);
      animation: animation-line-2 6s infinite linear;
    }
  }
  .text-center{
    max-width: rem(816px);
    margin: 0 auto;
  }
  .testimonials-slider{
    margin-bottom:rem(70px);
    @include media-breakpoint-down(sm) {
      padding-bottom: 3rem;
      margin-bottom:rem(30px);
    }
    .slick-prev,
    .slick-next{
      position: absolute;
      @extend .pos-centerY;
      border: 1px solid #8a8a8a;
      width: rem(60px);
      height: rem(60px);
      border-radius: 50%;
      cursor: pointer;
      color: #8a8a8a;
      z-index: 6;
      @include media-breakpoint-down(sm) {
        width: rem(40px);
        height: rem(40px);
        top: unset;
        bottom: 3rem;
        transform: none;
      }
    }
    .slick-prev{
      left: rem(0);
      cursor: pointer;
      background-image: center/ 1.5rem 1.5rem no-repeat url("../img/svg/back-brown.svg"),transparent;
      @include media-breakpoint-down(md) {
        background-image: center/ 1rem 1rem no-repeat url("../img/svg/back-brown.svg"),transparent;
      }
      &:hover,
      &:focus{
        outline: none;
        background-image: center/ 1.5rem 1.5rem no-repeat url("../img/svg/back-brown.svg"), cl(primary,0.2);
      }
    }
    .slick-next{
      right: 0;
      background-image: center/ 1.5rem 1.5rem no-repeat url("../img/svg/next-brown.svg"),transparent;
      @include media-breakpoint-down(md) {
        background-image: center/ 1rem 1rem no-repeat url("../img/svg/next-brown.svg"),transparent;
      }
      &:hover,
      &:focus {
        background-image: center/ 1.5rem 1.5rem no-repeat url("../img/svg/next-brown.svg"), cl(primary,0.2);
      }
    }
    .slick-dots{
      display: flex;
      align-items: center;
      justify-content: center;
      gap:rem(10px);
      list-style: none;
      li{
        &.slick-active{
          button {
            background-color: #8a8a8a;
          }
        }
        button {
          border:1px solid  #8a8a8a;
          width:rem(12px);
          height:rem(12px);
          padding: 0;
          overflow: hidden;
          text-indent: -999px;
          border-radius: 50%;
          flex-shrink: 0;
          background: transparent;
        }
      }
    }
    .item{
      padding: rem(16px 30px);
      @include media-breakpoint-down(md) {
        padding: 1rem 5rem;
      }
      @include media-breakpoint-down(sm) {
        padding: 1rem 1rem;
      }
      .item-inner{
        margin: 0 auto;
        text-align: center;
        color: cl(white);
        max-width: rem(816px);
        font-size: rem(20px);
        font-family: $fontP;
        line-height: rem(40px);
        letter-spacing: 0.05em;
      }
    }
  }
}
.home--founders-section{
  background-color: cl(white);
  position: relative;
  z-index: 1;
  padding: rem(70px 0 105px 0);
  .marque-wrap{
    margin-top:rem(20px);
    margin-bottom: rem(20px);
    overflow: hidden;
    .marquee-honor{
      b{
        padding-right: 10vw;
        font-size: 10vw;
        color: cl(white);
        line-height: 13vw;
        font-family: $fontJ;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
        vertical-align: middle;
        letter-spacing: 0.3em;
        img{
          padding-right: 2vw;
          height: 12vw;
          vertical-align: sub;
        }
      }
    }
  }
  .fonder-block{
    &-inner{
      width: 100%;
    }
    .text-side{
      text-align: center;
      padding: 0 4.5vw;
      @include media-breakpoint-down(md) {
        margin-top: 2rem;
        padding: 0 1rem;
      }
      img{
        margin: 0 0.5rem 1rem;
        height: rem(54px);
        filter: grayscale(100%);
      }
      p{
        margin-bottom: 2rem;
      }
      .sub-ttl{
        color: #545454;
        font-size: rem(20px);
        line-height: rem(30px);
        font-family: $fontP;
        letter-spacing: 0.05em;
        margin-bottom: rem(14px);
      }
      .name{
        position: relative;
        font-size: rem(30px);
        line-height: 1.2;
        margin: rem(0 0 30px);
        font-family: $fontJ;
        font-weight: 700;
        padding-bottom: rem(35px);
        &:before{
          content: "";
          position: absolute;
          width: rem(60px);
          height: rem(4px);
          left:50%;
          font-style: normal;
          transform: translateX(-50%);
          bottom: 0;
          background-color: cl(primary);
          animation: animation-line-2 6s infinite linear;
        }
      }
      .btns-wrap{
        margin-top:1rem;
        gap:1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .btn{
          color: #545454!important;
          border-radius: rem(2px 2px 2px 2px);
          @include media-breakpoint-down(xs) {
            width: 100%;
          }
        }

      }
    }
    .picture{
      box-shadow: rem(0px 17px 70px 0px rgba(0, 0, 0, 0.3));
      max-width: 100%;
      margin-right: rem(30px);
      max-height: 30.7vw;
      width: 100%;
      object-fit: cover;
      object-position: top;
      @include media-breakpoint-down(md) {
        margin: 0;
        max-height: unset;
      }
    }
    &-reverse{
      .picture{
        margin-right: rem(0px);
        margin-left: rem(30px);
        @include media-breakpoint-down(md) {
          margin: 0;
        }
      }
    }
  }

}

.home--contact-section{
  padding: rem(70px 0);
  z-index: 4;
  background-color: cl(white);
  position: relative;
  .block-wrap{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .video-side{
      padding-top: 2rem;
      width: 50%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .video-holder{
        margin-left: auto;
        width: 40vw;
        padding-bottom: 48.3%;
        position: relative;
        box-shadow: rem(0px 17px 70px 0px rgba(0, 0, 0, 0.3));
        @include media-breakpoint-down(lg) {
          width: 100%;
          padding-bottom: 56.38%;
        }
        video{
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
    }
    .contact-side {
      width: 50%;
      padding-left: 7vw;

      @include media-breakpoint-down(lg) {
        padding: 0 4rem 0 2rem;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-top: 3rem;
        padding: 0 4rem;
      }
      @include media-breakpoint-down(xs) {
        padding: 0;
      }
      h2{
        position: relative;
        font-size: rem(30px);
        line-height: 1.2;
        margin: rem(0 0 40px);
        font-family: $fontJ;
        font-weight: 700;
        padding-bottom: rem(26px);
        text-align: center;
        &:before{
          content: "";
          position: absolute;
          width: rem(60px);
          height: rem(4px);
          left:50%;
          font-style: normal;
          transform: translateX(-50%);
          bottom: 0;
          background-color: cl(black);
          animation: animation-line-2 6s infinite linear;
        }
      }
      .contact-form {
        margin-bottom: rem(-130px);
          position: relative;
          box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.3);
          background-color: cl(primary);
          width: rem(600px);
        max-width: 100%;
         padding: rem(45px 40px 20px 40px);
        @include media-breakpoint-down(md) {
          margin: 0 auto;
        }
          @include media-breakpoint-down(sm){
          width: 100%;
          margin: 0 auto;
        }
        .img-deco{
          position: absolute;
          right: rem(-100px);
          width: rem(240px);
          top: 0.5rem;
          @include media-breakpoint-down(lg) {
            right: -5rem;
            width: rem(200px);
          }
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
        .btn{
          @include media-breakpoint-down(xs){
            width: 100%;
          }
        }
        .form-group{
          margin-bottom: rem(30px);
          .form-control{
            border-color: cl(primary);
            &:focus{
              border:1px dashed cl(secondary);
            }
          }
        }
      }
    }
  }
}
.home--location-section{
  background-color: #272727;
  position: relative;
  z-index: 1;
  padding: rem(100px 0);
  @include media-breakpoint-down(sm) {
    padding: rem(50px 0);
  }
  h2{
    position: relative;
    padding-bottom: rem(40px);
    color: cl(white);
    @extend .ttu;
    text-align: center;
    &:before{
      content: "";
      position: absolute;
      width: rem(60px);
      height: rem(4px);
      left:50%;
      font-style: normal;
      transform: translateX(-50%);
      bottom: 0;
      background-color: cl(white);
      animation: animation-line-2 6s infinite linear;
    }
  }
  .location-grid{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    max-width: 83.75vw;
    margin: 0 auto;
    flex-wrap: wrap;
    .item{
     // width: 25%;
      width: 50%;
      display: flex;
      align-items: stretch;
      color: cl(white);
      @include media-breakpoint-down(md) {
        width: 100%;
        flex-direction: row;
        align-items: stretch;
      }
      &:nth-child(2){
        @include media-breakpoint-down(md) {
          flex-direction: row;
          order: 1;
        }
      }
      &:nth-child(3){
        flex-direction: row-reverse;
        @include media-breakpoint-down(md){
         // flex-direction: row;

        }
      }

      &:nth-child(4){
        flex-direction: row-reverse;
        @include media-breakpoint-down(md) {
          order: 1;
        }
      }
      &:nth-child(1){
        .letter-block{
          span{
          margin-left: -3vw;
          }
        }
      }
      &:nth-child(4){
        .letter-block{
          span{
            margin-left: 4.6vw;
          }
        }
      }
      .letter-block{
      // height: rem(250px);
        position: relative;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
          width: 50%;
        @include media-breakpoint-down(xs) {
          display: none;
        }
        span {
          font-family: $fontJ;
          font-size: 10.4vw;
          line-height: 10.4vw;
          position: relative;
          letter-spacing: 0.2em;
          font-weight: 700;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 1rem;
          color: rgba(255, 255, 255, 0);
          text-stroke: 1px cl(white, 0.1);
          -webkit-text-fill-color: rgba(255, 255, 255, 0); /* Will override color (regardless of order) */
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: cl(white, 0.1);
          @include media-breakpoint-down(md) {
            font-size: 15.4vw;
            line-height: 15.4vw;
          }
        }
        .letter-deco{
          color: cl(white);
          font-size: rem(30px);
          font-family: $fontJ;
          font-weight: 700;
          position: absolute;
          left: 0;
          @extend .pos-centerY;
        }
      }
      .contact-info-block{
        flex-grow: 1;
        flex-shrink: 0;
        font-size: rem(15px);
        line-height: rem(25px);
        color: #c6c6c6;
        @extend .ttu;
        background-color: #0a0a0a;
        padding: rem(40px 35px);
        box-shadow: rem(0px 17px 70px 0px rgba(0, 0, 0, 0.3));
          width: 50%;
        @include media-breakpoint-down(xs) {
          width: 100%;
          margin-bottom: 1rem;
        }
        .ttl{
          color: cl(white);
          @extend .ttu;
          font-family: $fontJ;
          font-weight: 700;
          font-size: rem(16px);
          line-height: rem(25px);
          margin-bottom: 0.5rem;
        }
        a{
          color: #c6c6c6;
          display: block;
        }
        .directions-link{
          margin-top: 1rem;
          color: cl(primary);
          text-transform: none;
        }
      }

    }
  }
}
.home--why-section{
  position: relative;
  z-index: 1;
  background-color: cl(white);
  padding: rem(100px 0);
  @include media-breakpoint-down(sm) {
    padding: rem(50px 0);
  }
  .why-grid{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    max-width: 83.75vw;
    margin: 0 auto;
    flex-wrap: wrap;
    .item{
      width: 25%;
      display: flex;
      align-items: stretch;
      color: cl(white);
      flex-direction: column-reverse;
      @include media-breakpoint-down(md) {
        width: 50%;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
      &:nth-child(even){
        flex-direction: column;
      }
      &:nth-child(2){
        @include media-breakpoint-down(md) {
          flex-direction: column-reverse;
        }
        .contact-info-block {
          background-color: #242424;
        }
      }
      &:nth-child(3){
        @include media-breakpoint-down(md) {
          flex-direction: column;
        }
        .contact-info-block {
          background-color: #4c4b4b;
        }
      }
      &:nth-child(4){
        .contact-info-block {
          background-color: cl(primary);
        }
      }
      .letter-block{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 9vw;
        @include media-breakpoint-down(xs) {
          display: none;
        }
        span {
          font-family: $fontJ;
          font-size: 6.2vw;
          line-height: 6.2vw;
          position: relative;
          letter-spacing: 0.3em;
          font-weight: 700;
          text-transform: uppercase;
          text-shadow: -6.101px 13.703px 43px rgba(0, 0, 0, 0.11);
          @include media-breakpoint-down(md) {
            font-size: 12.4vw;
            line-height: 12.4vw;
          }
        }
        img{
          height: 7.2vw;
          @include media-breakpoint-down(md) {
            height: 12.4vw;
          }
        }
      }
      .contact-info-block{
        flex-grow: 1;
        flex-shrink: 0;
        font-size: rem(15px);
        line-height: rem(25px);
        color: cl(white);
        @extend .ttu;
        background-color: #0a0a0a;
        padding: rem(40px 35px);
        box-shadow: rem(0px 17px 70px 0px rgba(0, 0, 0, 0.3));
        @include media-breakpoint-down(xs) {
          width: 100%;
          margin-bottom: 1rem;
        }
        .ttl{
          color: cl(white);
          @extend .ttu;
          font-family: $fontJ;
          font-weight: 700;
          font-size: rem(16px);
          line-height: rem(25px);
          margin-bottom: rem(14px);
        }
        .line{
          background-color: rgb(255, 255, 255);
          width: 62%;
          height: 1px;
          margin-bottom: 1rem;
        }
        a{
          color: #c6c6c6;
          display: block;
        }
        .directions-link{
          margin-top: 1rem;
          color: cl(primary);
          text-transform: none;
        }
        img{
          height: 2rem;
          margin-bottom: rem(16px);
        }
      }

    }
  }
}

.home-insta-section{
  padding: rem(90px 10px 0px 10px);
  text-align: center;
  h2{
    position: relative;
    padding-bottom: rem(40px);
    &:before{
      content: "";
      position: absolute;
      width: rem(60px);
      height: rem(4px);
      left:50%;
      font-style: normal;
      transform: translateX(-50%);
      bottom: 0;
      background-color: cl(primary);
      animation: animation-line-2 6s infinite linear;
    }
  }
  .insta-grid{
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-gap: 0.625rem 0.625rem;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    .item{
      width: 100%;
      padding-bottom: 100%;
      display: block;
      position: relative;
      margin-bottom: 0.625rem;
      background-color: cl(primary);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      img{
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left: 0;
        object-fit: cover;
      }
    }
  }
  .insta-grid-slider{
    display: none;
    margin-top: 4rem;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    .column{
      margin: 0.625rem;
    }
    .slick-prev,
    .slick-next {
      position: absolute;
      color: cl(white);
      font-size:rem(20px);
      flex-shrink: 0;
      right: 4rem;
      top: -3rem;
      cursor: pointer;
      border-radius: 50%;
      background-color: cl(primary);
      width: 1.75rem;
      height: 1.75rem;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-down(md) {
        font-size:rem(16px);
      }
      &:hover {
        color: cl(white);
      }

    }
    .slick-next {
      right: 0;
    }
    .item{

      width: 100%;
      padding-bottom: 100%;
      display: block;
      position: relative;
      background-color: cl(primary);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 0.625rem;
      img{
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left: 0;
        object-fit: cover;
      }
    }
  }
}