.static {
  font-size: rem(18px);
  line-height: rem(30px);
  color:cl(text-gray);
  font-family: $font;
  padding: rem(60px 0 40px 0);
  p {
    margin: rem(0 0 20px);
  }
 h2{
   text-align: center;
 }
  h4 {
    font-size: rem(22px);
    line-height: rem(32px);
    margin: rem(0 0 20px);
    font-family: $font;
    font-weight: 400;
    color: cl(text);
    @include media-breakpoint-down(xs) {
      font-size: rem(18px);
    }
  }
  h5 {
    font-size: rem(18px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  h6 {
    font-size: rem(16px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  ol, ul {
    margin: rem(0 0 20px);
    padding: rem(0 0 0 40px);
  }
  ul{
    list-style: none;
    position: relative;
    z-index: 3;
    margin-bottom: 1.5rem;
    li{
      position: relative;
      padding: rem(2px 0 2px 15px);
      &:after{
        content: '';
        position: absolute;
        top:rem(12px);
        left: 0;
        width: rem(7px);
        height: rem(7px);
        border-radius: 50%;
        line-height: rem(20px);
        background-color: cl(primary);
      }
    }
  }
  img {
    display: block;
    margin: 0 auto 2rem;
    max-width:100%;
    height: auto;
  }
  .video-holder{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.3);
    background-color: cl(black);
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    video{
      width: 100%;
      object-fit: contain;
      object-position: center;
    }

  }
}