/* banner start */
.banner {
  position: relative;
  background: center/cover no-repeat;
  color: cl(white);

  &--home {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: rem(90px);
    position: relative;
    background: center/cover no-repeat;
    background-attachment: fixed;
    @include media-breakpoint-down(md) {
      height: rem(600px);
      background-attachment:scroll;
    }
    @include media-breakpoint-down(sm) {
      padding-top: rem(60px);
      height: rem(600px);
    }
    @include media-breakpoint-down(xs) {
      padding-top: rem(60px);
      min-height: rem(600px);
      height: unset;
    }
    &:after{
      content: "";
      @extend .full;
      background-color: cl(black, 0.28);
    }

    .advocats-pic{
      position: absolute;
      bottom: 0;
      @extend .pos-centerX;
      max-height: 80vh;
      width: 68.75vw;
      object-position: center bottom;
      z-index: 5;
      object-fit: contain;
      @include media-breakpoint-down(xs){
        width: 98vw;
        height: 80%;
        position: relative;

      }
    }
    .banner__text {
      padding-bottom: 5vw;
      position: relative;
      width:100%;
      height: 100%;
      color:cl(white);
      z-index: 6;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      @include media-breakpoint-down(xs) {
        padding-top: rem(180px);
        padding-bottom: 3rem;
      }
      .banner__ttl{
        font-size: rem(70px);
        line-height: rem(70px);
        text-shadow: 0px 9px 40px rgba(0, 0, 0, 0.3);
        font-family: $fontJ;
        color: cl(white);
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: rem(20px);
        @include media-breakpoint-down(md) {
          font-size: rem(45px);
          line-height: rem(55px);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(30px);
          line-height: rem(30px);
          margin-bottom: 0.5rem;
        }
      }
      .banner__sub-ttl{
        font-size: rem(30px);
        line-height: rem(40px);
        text-shadow: 0px 9px 40px rgba(0, 0, 0, 0.3);
        font-family: $font;
        color: cl(white);
        font-weight: 400;
        text-align: center;
        @include media-breakpoint-down(xs) {
          font-size: rem(20px);
          line-height: rem(30px);
        }
      }
      p {
        margin: 0;
      }

    }
  }
  &--page {
    height: rem(260px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: rem(10px 0 10px 0);
    background:center/cover no-repeat, cl(brown-light);
    color: cl(white);
    //@include media-breakpoint-down(xs){
    //  height: rem(370px);
    //}
    .container {
      position: relative;
      z-index: 2;
    }
    .banner__text {
      position: relative;
      width:100%;
      height: unset;
      color:cl(white);
      z-index: 6;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 0;
      .banner__ttl{
        font-size: rem(50px);
        line-height: rem(50px);
        text-shadow: 0px 9px 40px rgba(0, 0, 0, 0.3);
        font-family: $fontJ;
        color: cl(white);
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: rem(20px);
        @include media-breakpoint-down(md) {
          font-size: rem(40px);
          line-height: rem(50px);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(30px);
          line-height: rem(30px);
          margin-bottom: 0.5rem;
        }
      }
      .banner__sub-ttl{
        font-size: rem(30px);
        line-height: rem(40px);
        text-shadow: 0px 9px 40px rgba(0, 0, 0, 0.3);
        font-family: $font;
        color: cl(white);
        font-weight: 400;
        text-align: center;
        @include media-breakpoint-down(xs) {
          font-size: rem(20px);
          line-height: rem(30px);
        }
      }
      p {
        margin: 0;
      }

    }
    .letter-decor{
      position: absolute;
      @extend .pos-center;
      font-family: $fontJ;
      font-size: 8vw;
      line-height: 8vw;
      letter-spacing: 0.3em;
      font-weight: 700;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 6vw;
      color: rgba(255, 255, 255, 0);
      text-stroke: 2px cl(white,0.1);
      -webkit-text-fill-color: rgba(255, 255, 255, 0); /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: cl(white,0.1);
      overflow: hidden;
      text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.004));
      z-index: 0;
      @include media-breakpoint-down(sm) {
        font-size: 13vw;
        line-height: 13vw;
        letter-spacing: 0.2em;
      }
      &-long{
        font-size: 7vw;
        line-height: 7vw;
        @include media-breakpoint-down(sm) {
          font-size: 7vw;
          line-height: 7vw;
          letter-spacing: 0.2em;
        }
      }
      .word{
        display: flex;
      }
    }
  }
  &-video {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    //position: absolute;
    z-index: 0;
    @include media-breakpoint-down(md) {
      position: absolute;
    }
    &.banner-video-absolute{
      position: absolute;
    }
    &:after{
      content: "";
      @extend .full;
      background-color: cl(black, 0.2);
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  &__btns-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(45px);
    margin-top: 2rem;
    @include media-breakpoint-down(xs) {
      margin-top: 1rem;
    }
    .btn{
      min-width: rem(190px);
    }
    .watch-video-banner{
      .watch{
        display: block;
      }
      .stop{
        display: none;
      }
      &.stop-video{
        .watch{
          display: none;
        }
        .stop{
          display: block;
        }
      }

    }
  }
  .scroll-to-section{
    position: absolute;
    z-index: 14;
    @extend .pos-centerX;
    bottom: 1rem;
    img{
      height: rem(29px);
    }
  }
}
/* banner end */

