.about-banner-section{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  .text-side{
    width: 50%;
    background-color: cl(brown-light);
    color: #c6c6c6;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 6vw;
    font-size: rem(15px);
    position: relative;
    line-height: rem(25px);
    padding-bottom: 12vw;
    @include media-breakpoint-down(md) {
      width: 100%;
      order: 1;
      padding-top: rem(70px);
      padding-bottom: 24vw;
     }
    @include media-breakpoint-down(xs) {
      padding-top: rem(30px);
    }
    .text-side-inner{
      padding-right: rem(60px);
      max-width: rem(552px);
      @include media-breakpoint-down(md) {
        max-width: 100%;
        padding: 0 2rem;
        text-align: center;
      }
    }
    h2{
      color: cl(white);
      margin-bottom: rem(32px);
      text-shadow: 0px 9px 40px rgba(0, 0, 0, 0.3);
    }
    p{
      margin-bottom: 1.5rem;
    }
    .letter-decor{
      @extend .pos-centerX;
      bottom: 3vw;
      text-shadow: -6.101px 13.703px 43px rgba(0, 0, 0, 0.004);
      margin-top: -2vw;
      margin-bottom: -1vw;
      font-family: $fontJ;
      font-size: 8.3vw;
      line-height: 8.3vw;
      letter-spacing: 0.3em;
      font-weight: 700;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 1rem;
      color: rgba(255, 255, 255, 0);
      text-stroke: 2px cl(white,0.1);
      -webkit-text-fill-color: rgba(255, 255, 255, 0); /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: cl(white,0.1);
      overflow: hidden;
      z-index: 0;
      @include media-breakpoint-down(md) {
        font-size: 18vw;
        line-height: 18vw;
      }

      .word{
        display: flex;
      }
    }
    .sub-ttl{
      text-shadow: 0px 9px 40px rgba(0, 0, 0, 0.3);
      font-size: rem(20px);
      line-height: rem(30px);
      font-family: $fontP;
      letter-spacing: 0.05em;
      margin-bottom: rem(14px);
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }
  .image-side{
    width: 50%;
    min-height:34.47vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include media-breakpoint-down(md) {
      width: 100%;
      height:54.47vw;
    }
  }
}
.about-info-section{
  padding: rem(70px 0 0 0);
  color: cl(black);
  font-size: rem(16px);
  line-height: rem(26px);
  p{
    margin-bottom: rem(20px);
  }
  a{
    color: inherit;
  }
  h2{
    position: relative;
    font-size: rem(30px);
    line-height: 1.2;
    margin: rem(0 0 25px);
    font-family: $fontJ;
    font-weight: 700;
    padding-bottom: rem(20px);
    text-align: center;
    @extend .ttu;
    &:before{
      content: "";
      position: absolute;
      width: rem(60px);
      height: rem(4px);
      left:50%;
      font-style: normal;
      transform: translateX(-50%);
      bottom: 0;
      background-color: cl(primary);
      animation: animation-line-2 6s infinite linear;
    }
  }
  .letter-decor{
    font-size: 10.4vw;
    color: cl(white);
    line-height: 14vw;
    font-family: $fontJ;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
    letter-spacing: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 6vw;
    overflow: hidden;
    z-index: 0;
   // margin-bottom: -3.5vw;
    margin-top: -2vw;
    //@include media-breakpoint-down(md) {
    //  font-size: 14.4vw;
    //  line-height: 14.4vw;
    //}

    .word{
      display: flex;
    }
  }
}

.about-team-section{
  padding: rem(20px 0 40px 0);
  .team-grid{
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap:2rem;
    flex-wrap: wrap;
    .item{
      width: calc(33.33% - 1.5rem);
      padding-bottom: 44%;
      position: relative;
      box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.3);
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      @include media-breakpoint-down(sm) {
        padding-bottom: 70%;
        width: calc(50% - 1.5rem);
      }
      @include media-breakpoint-down(xs) {
        padding-bottom: 140%;
        width: 100%;
      }
      .text{
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        color: cl(white);
        padding: 1rem 1rem 1.5rem 1rem;
        text-align: center;
        @include media-breakpoint-down(xs) {
          padding: 1rem 1rem 2.5rem 1rem;
        }
        .name{
          font-size: rem(24px);
          line-height: 1.2;
          margin: rem(0 0 5px);
          font-family: $fontJ;
          font-weight: 700;
          letter-spacing: 0.1em;
          @include media-breakpoint-down(xs) {
            font-size: rem(44px);
          }
        }
        .position{
          font-size: rem(20px);
          line-height: rem(30px);
          font-family: $fontP;
          letter-spacing: 0.05em;
          @include media-breakpoint-down(xs) {
            font-size: rem(35px);
          }
        }
      }

    }
  }
  h2{
    position: relative;
    font-size: rem(30px);
    line-height: 1.2;
    margin: rem(0 0 25px);
    font-family: $fontJ;
    font-weight: 700;
    padding-bottom: rem(20px);
    text-align: center;
    @extend .ttu;
    &:before{
      content: "";
      position: absolute;
      width: rem(60px);
      height: rem(4px);
      left:50%;
      font-style: normal;
      transform: translateX(-50%);
      bottom: 0;
      background-color: cl(primary);
      animation: animation-line-2 6s infinite linear;
    }
  }
  .letter-decor{
    font-size: 10.4vw;
    color: cl(white);
    line-height: 14vw;
    font-family: $fontJ;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
    letter-spacing: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 6vw;
    overflow: hidden;
    z-index: 0;
    //margin-bottom: -3.5vw;
   // margin-top: -3vw;
    //@include media-breakpoint-down(md) {
    //  font-size: 14.4vw;
    //  line-height: 14.4vw;
    //}

    .word{
      display: flex;
    }
  }
}