.deco-letters-section{
  .marque-wrap{
    overflow: hidden;
    margin-top: -3vw;
    .marquee-honor{
      b{
        padding-right: 10vw;
        font-size: 10vw;
        color: cl(white);
        line-height: 13vw;
        font-family: $fontJ;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
        vertical-align: middle;
        letter-spacing: 0.3em;
        img{
          padding-right: 2vw;
          height: 12vw;
          vertical-align: sub;
        }
      }
    }
  }
}
.team-main-section{
  padding: rem(100px 0 0 0);
  position: relative;
  .container{
    max-width: rem(1390px);
  }
  .team-item{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: rem(70px);
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    .text-side{
      width: calc(100% - 35.62rem);
      text-align: left;
      padding: rem(0 120px 0 70px);
      @include media-breakpoint-down(md) {
        margin-top: 2rem;
        width: 100%;
        padding: 0;
        text-align: center;
      }
      img{
        margin: 0 0.5rem 1rem;
        height: rem(54px);
        filter: grayscale(100%);
      }
      p{
        margin-bottom: 2rem;
      }
      .sub-ttl{
        color: #545454;
        font-size: rem(20px);
        line-height: rem(30px);
        font-family: $fontP;
        letter-spacing: 0.05em;
        margin-bottom: rem(14px);
        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }
      .name{
        position: relative;
        font-size: rem(30px);
        line-height: 1.2;
        margin: rem(0 0 20px);
        font-family: $fontJ;
        font-weight: 700;
        padding-bottom: rem(35px);
        &:before{
          content: "";
          position: absolute;
          width: rem(60px);
          height: rem(4px);
          left:0%;
          font-style: normal;
          bottom: 0;
          background-color: cl(primary);
          //animation: animation-line-2 6s infinite linear;
          @include media-breakpoint-down(md) {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .btns-wrap{
        margin-top:1rem;
        gap:1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        @include media-breakpoint-down(md) {
          justify-content: center;
        }
        .btn{

          @include media-breakpoint-down(xs) {
            width: 100%;
          }
        }

      }
    }
    .image-side{
      width:35.62rem;
      @include media-breakpoint-down(md) {
       width: 100%;
        max-width: 35.62rem;
        margin: 0 auto;
      }
      .picture{
        box-shadow: rem(0px 17px 70px 0px rgba(0, 0, 0, 0.3));
        max-width: 100%;
        max-height: rem(470px);
        width: 100%;
        object-fit: cover;
        object-position: top;
        @include media-breakpoint-down(md) {
          margin: 0;
          max-height: 80vw;
        }
      }
    }

  }
}
.socials-section{
  min-height:13.5vw;
  background-color: cl(white);
  position: relative;
  z-index: 3;
  padding: rem(60px 0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include media-breakpoint-down(sm) {
    min-height: 16vw;
  }
  .letter-decor{
    margin-top: -1vw;
    position: absolute;
    @extend .pos-center;
    font-size: 10.4vw;
    color: cl(white);
    line-height: 14vw;
    font-family: $fontJ;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
    letter-spacing: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    //overflow: hidden;
    z-index: 0;
    @include media-breakpoint-down(md) {
      font-size: 14vw;
      line-height: 14vw;
    }

    .word{
      display: flex;
    }
  }
  .socials-grid{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap:2.5rem;
    position: relative;
    z-index: 4;
    a{
      white-space: nowrap;
      font-size: rem(15px);
      color: #1e1e1e;
      i{
        font-size: rem(22px);
        margin-right: 0.375rem;
      }
    }
  }
}

.commercial-videos-section{
  padding: rem(70px 0);
  .container{
    max-width: rem(1290px);
  }
  .commercial-videos-grid{
    display: flex;
    align-items: flex-start;
    gap:rem(30px 60px);
    flex-wrap: wrap;
    .item{
      width: calc(50% - 2rem);
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      .video-holder{
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        width: 100%;
        box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.3);
        background-color: cl(black);
        iframe,
        object,
        embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        video{
          width: 100%;
          object-fit: contain;
          object-position: center;
        }

      }
    }

  }
}
.analysis-section{
  background-color: cl(primary);
  min-height: 12.7vw;
  padding: rem(50px 0);
  position: relative;
  font-size: rem(16px);
  color: cl(white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
  div,p,span,h2,h3,h4,h5,h6,a,button{
    &:focus{
      outline: 1px dashed cl(secondary);
    }
  }

  .letter-decor{
    margin-top: -1vw;
    position: absolute;
    @extend .pos-center;
    font-size: 10.4vw;
    color: cl(white,0.1);
    line-height: 14vw;
    font-family: $fontJ;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
    letter-spacing: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    //overflow: hidden;
    z-index: 0;
    @include media-breakpoint-down(md) {
      font-size: 11vw;
      line-height: 11vw;
    }

    .word{
      display: flex;
    }
  }
}
.case-page{
  padding: rem(70px 0);
  .ttl-wrap{
    position: relative;
    margin: rem(0 0 20px);
    text-align: center;
    padding-bottom: rem(22px);

    h2{
      @extend .ttu;
    }
    &:before {
      content: "";
      position: absolute;
      width: rem(60px);
      height: rem(4px);
      font-style: normal;
      bottom: 0;
      background-color: cl(primary);
      animation: animation-line-2 6s infinite linear;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .sub-ttl{
    color: #545454;
    font-size: rem(20px);
    line-height: rem(30px);
    font-family: $fontP;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 0;
  }
  .text-side{
    color: cl(black);
    font-size: rem(16px);
    line-height: 1.5;
    padding-right: 2rem;
    font-family: $font;
    @include media-breakpoint-down(md) {
      padding-right: 0;
    }
    a{
      color: inherit;
    }
    p{
      margin-bottom: 2rem;
    }
    .ttl-wrap{
      position: relative;
      margin: rem(0 0 20px);
      text-align: left;
      padding-bottom: rem(22px);
      &:before {
        content: "";
        position: absolute;
        width: rem(60px);
        height: rem(4px);
        font-style: normal;
        bottom: 0;
        background-color: cl(primary);
        animation: none;
        left: 0%;
        transform: translateX(0);
      }
      .sub-ttl{
        text-align: left;
        margin-bottom: 1rem;
      }
    }

  }
  .contact--form-wrap{
    margin-left: 1vw;
    width: 46.19vw;
    box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.3);
    padding: rem(80px 30px 20px 30px);
    background: center/cover no-repeat url('../img/pic-10.jpg');
    background-attachment: fixed;
    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-left: 0;
      background-attachment: scroll;
    }
    .ttl-wrap{
      margin: 0 auto 2.5rem;
      text-align: center;
      padding-bottom: rem(15px);
      max-width: rem(350px);
      &:before {
        background-color: cl(black);
      }
      .sub-ttl{
        color: cl(black);

      }
    }
    .form-holder{
      width: rem(505px);
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.case--location-section{
  position: relative;
  z-index: 1;
  padding: rem(100px 0);
  @include media-breakpoint-down(sm) {
    padding: rem(50px 0);
  }
  h2{
    position: relative;
    padding-bottom: rem(40px);
    color: cl(black);
    @extend .ttu;
    text-align: center;
    &:before{
      content: "";
      position: absolute;
      width: rem(60px);
      height: rem(4px);
      left:50%;
      font-style: normal;
      transform: translateX(-50%);
      bottom: 0;
      background-color: cl(primary);
      animation: animation-line-2 6s infinite linear;
    }
  }
  .location-grid{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    max-width: 83.75vw;
    margin: 0 auto;
    flex-wrap: wrap;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
    .item{
      width: 25%;
      display: flex;
      align-items: stretch;
      flex-direction: column-reverse;
      color: cl(white);
      @include media-breakpoint-down(md) {
        width: 100%;
        flex-direction: row;
        align-items: stretch;
      }
      &:nth-child(1){
        .letter-block{
          span{
            margin-left: -3vw;
          }
        }
      }
      &:nth-child(2){
        flex-direction:column;
        @include media-breakpoint-down(md) {
          flex-direction: row-reverse;
         // order: 1;
        }
        .contact-info-block{
          background-color: #222222;
        }
      }
      &:nth-child(3){
        flex-direction: column-reverse;
        @include media-breakpoint-down(md) {
          flex-direction: row;
        }
        .contact-info-block{
          background-color: #4c4b4b;

        }
      }
      &:nth-child(4){
        flex-direction: column;
        color: cl(white);
        @include media-breakpoint-down(md) {
          order: 1;
          flex-direction: row-reverse;
        }
        div,p,span,h2,h3,h4,h5,h6,a,button{
          &:focus{
            outline: 1px dashed cl(secondary);
          }
        }
        .contact-info-block{
          background-color: cl(primary)
        }
      }
      .letter-block{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 7.8vw;
        @include media-breakpoint-down(md) {
          height: unset;
        }
        @include media-breakpoint-down(xs) {
          display: none;
        }
        span {
          font-size: 6.2vw;
          color: cl(white);
          line-height: 1;
          font-family: $fontJ;
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
          position: relative;
          text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 1rem;
          z-index: 0;
          letter-spacing: 0.2em;

          @include media-breakpoint-down(md) {
            font-size: 15.4vw;
            line-height: 15.4vw;
          }
        }
        .letter-deco{
          color: cl(black);
          font-size: rem(30px);
          font-family: $fontJ;
          font-weight: 700;
          position: absolute;
          left: 0;
          @extend .pos-centerY;
        }
      }
      .contact-info-block{
        //min-height: rem(300px);
        flex-grow: 1;
        font-size: rem(15px);
        line-height: rem(25px);
        color: cl(white);
        background-color: #0a0a0a;
        padding: rem(40px 35px);
        box-shadow: rem(0px 17px 70px 0px rgba(0, 0, 0, 0.3));
        width: 100%;
        @include media-breakpoint-down(xs) {
          width: 100%;
          margin-bottom: 1rem;
        }
        img{
          height: rem(32px);
          margin-bottom: rem(26px);
          animation: animation-pulse-1 4s infinite;
        }
        .line{
          background-color: rgb(255, 255, 255);
          width: 62%;
          height: 1px;
          margin-bottom: 1.25rem;
        }

        .ttl{
          color: cl(white);
          @extend .ttu;
          font-family: $fontJ;
          font-weight: 700;
          font-size: rem(16px);
          line-height: rem(25px);
          margin-bottom: 1.25rem;
        }
        .mb-5{
          margin-bottom: rem(32px) !important;
        }
        .phone{
          border-radius: rem(2px);
          color: cl(black);
          text-align: center;
          padding: rem(7px 20px);
          line-height: rem(26px);
          font-size: rem(16px);
          white-space: nowrap;
          overflow: hidden;
          background-color: cl(white);
          font-family: $fontJ;
          font-weight: 700;
          border: 1px solid cl(white);
          &:hover{
            text-decoration: none;
            background-color: cl(white , 0.4);
          }
        }

      }

    }
  }
}

.team-inner-banner-section{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: cl(brown-light);
  .text-side{
    width: 50%;
    color: cl(white);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-top: 6vw;
    font-size: rem(17px);
    position: relative;
    line-height: rem(30px);
    padding-bottom: 12vw;
    @include media-breakpoint-down(md) {
      width: 100%;
      order: 1;
      padding-top: rem(70px);
      padding-bottom: 24vw;
    }
    @include media-breakpoint-down(xs) {
      padding-top: rem(30px);
    }
    .text-side-inner{
      padding-right: rem(60px);
      max-width: rem(552px);
      @include media-breakpoint-down(md) {
        max-width: 100%;
        padding: 0 2rem;
        text-align: center;
      }
    }
    //h2{
    //  color: cl(white);
    //  margin-bottom: rem(32px);
    //  text-shadow: 0px 9px 40px rgba(0, 0, 0, 0.3);
    //}
    p{
      margin-bottom: 1.5rem;
    }
    img{
      margin: 0 0.5rem 1rem;
      height: rem(54px);
      filter: grayscale(100%);
    }
    p{
      margin-bottom: 2rem;
    }
    .sub-ttl{
      color: cl(white);
      font-size: rem(20px);
      line-height: rem(30px);
      font-family: $fontP;
      letter-spacing: 0.05em;
      margin-bottom: rem(14px);
    }
    .name{
      position: relative;
      font-size: rem(30px);
      line-height: 1.2;
      margin: rem(0 0 30px);
      font-family: $fontJ;
      font-weight: 700;
      padding-bottom: rem(35px);
      &:before{
        content: "";
        position: absolute;
        width: rem(60px);
        height: rem(4px);
        left:0%;
        font-style: normal;
        transform: translateX(0%);
        bottom: 0;
        background-color: cl(primary);
        //animation: animation-line-2 6s infinite linear;
        @include media-breakpoint-down(md) {
          transform: translateX(-50%);
          left: 50%;
        }
      }
    }
    .btns-wrap{
      margin-top:1rem;
      gap:1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      @include media-breakpoint-down(md) {
        justify-content: center;
      }
      .btn{
        min-width: rem(200px);
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }

    }
    .letter-decor{
      @extend .pos-centerX;
      bottom: 3vw;
      text-shadow: -6.101px 13.703px 43px rgba(0, 0, 0, 0.004);
      margin-top: -2vw;
      margin-bottom: -1vw;
      font-family: $fontJ;
      font-size: 8.3vw;
      line-height: 8.3vw;
      letter-spacing: 0.3em;
      font-weight: 700;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 1rem;
      color: rgba(255, 255, 255, 0);
      text-stroke: 2px cl(white,0.1);
      -webkit-text-fill-color: rgba(255, 255, 255, 0); /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: cl(white,0.1);
      overflow: hidden;
      z-index: 0;
      @include media-breakpoint-down(md) {
        font-size: 18vw;
        line-height: 18vw;
      }

      .word{
        display: flex;
      }
    }
    .sub-ttl{
      text-shadow: 0px 9px 40px rgba(0, 0, 0, 0.3);
      font-size: rem(20px);
      line-height: rem(30px);
      font-family: $fontP;
      letter-spacing: 0.05em;
      margin-bottom: rem(14px);
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }
  .image-side{
    width: 50%;
    min-height:calc(100vh - 6rem);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include media-breakpoint-down(md) {
      width: 100%;
      min-height:94.47vw;
    }
  }
}

.team--about-info-section{
  padding: rem(70px 0 0 0);
  color: cl(black);
  font-size: rem(17px);
  line-height: rem(30px);
  p{
    margin-bottom: rem(20px);
  }
  a{
    color: inherit;
  }

  .letter-decor{
    font-size: 6vw;
    color: cl(white);
    line-height: 9vw;
    font-family: $fontJ;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
    letter-spacing: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2vw;
    overflow: hidden;
    z-index: 0;
    // margin-bottom: -3.5vw;
    margin-top: -2vw;
    //@include media-breakpoint-down(md) {
    //  font-size: 14.4vw;
    //  line-height: 14.4vw;
    //}

    .word{
      display: flex;
    }
  }
  .ttl-wrap{
    position: relative;
    margin: rem(0 0 20px);
    text-align: left;
    padding-bottom: rem(22px);

    h2{
      @extend .ttu;
      position: relative;
      font-size: rem(30px);
      line-height: 1.2;
      margin: rem(0 0 15px);
      font-family: $fontJ;
      font-weight: 700;
    }
    .sub-ttl{
      color: #545454;
      font-size: rem(20px);
      line-height: rem(30px);
      font-family: $fontP;
      letter-spacing: 0.05em;
      margin-bottom: 0;
    }
    &:before {
      content: "";
      position: absolute;
      width: rem(60px);
      height: rem(4px);
      font-style: normal;
      bottom: 0;
      background-color: cl(primary);
      // animation: animation-line-2 6s infinite linear;
      left: 00%;
      transform: translateX(00%);
    }
  }
  img{
    margin: 0 0.5rem 1rem;
    height: rem(54px);
    filter: grayscale(100%);
  }
}

.posts-main-page{
  position: relative;
  padding: rem(50px 0);
  min-height: rem(500px);
  z-index: 4;
  @include media-breakpoint-down(md) {
   flex-wrap: wrap;
  }
  .right-block{
      position: absolute;
      width: rem(380px);
      background-color: #0a0a0a;
   // background-color: #242424;
      color: cl(white);
      font-size: rem(15px);
      line-height: rem(25px);
      padding: rem(40px 30px 30px 30px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.3);
      z-index: 19;
      top: rem(420px);
      right: 2rem;
    transition: 0.5s all ease-in-out;
    @include media-breakpoint-down(md) {
      position: relative;
      top: unset;
      right: unset;
      width: calc(100% - 3rem);
      margin: 0 auto;
    }
    @include media-breakpoint-down(sm) {
      width: calc(100% - 30px);
    }
      &.right-block-fixed{
        position: fixed;
        top: rem(430px);
        right: 2rem;
        @include media-breakpoint-down(md) {
         position: relative;
          top: unset;
          right: unset;
          width: calc(100% - 4.5vw - 30px);
          margin: 0 auto;

        }
        @include media-breakpoint-down(sm) {
          width: calc(100% - 30px);
        }
      }
      //@include media-breakpoint-down(md) {
      //  width: calc(50% - 0.5rem);
      //}
      //@include media-breakpoint-down(sm) {
      //  width: 100%;
      //}

      .item-inner{
        //min-height: rem(190px);
        flex-grow: 1;
        @include media-breakpoint-down(sm) {
          min-height: rem(190px);
        }
        @include media-breakpoint-down(xs) {
          min-height: rem(240px);
        }
      }
      .schedule-list{
        width: 100%;
        ul{
          list-style: none;
          width: 100%;
          li{
            color: cl(white);
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: rem(15px);
            line-height: rem(26px);
            padding: rem(7px 0);
            border-bottom: 1px solid rgba(255, 255, 255, 0.239);
            &:last-child{
              border: 0;
            }
          }
        }
      }
      .tel{
        display: block;
        color: cl(primary);
        margin-bottom: 0.5rem;
        font-size: rem(20px);
        i{
          animation: animation-pulse-1 2s infinite;
        }
      }
      img{
        height: 1.5rem;
        margin-bottom: rem(20px);
      }
      .ttl{
        color: cl(white);
        @extend .ttu;
        font-family: $fontJ;
        font-weight: 700;
        font-size: rem(16px);
        line-height: rem(25px);
        margin-bottom: rem(14px);
      }
      .line{
        background-color: rgb(255, 255, 255);
        width: 62%;
        height: 1px;
        margin-bottom: 1.25rem;
      }

  }
  .posts-block{
    width: calc(100% - 25rem);
    @include media-breakpoint-down(md) {
      position: relative;
      top: unset;
      right: unset;
      width: 100%;
    }
  }
  .post-item{
    background-color: #494949;
    padding: rem(30px 20px);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: rem(30px);
    color: cl(white);
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    .text-side{
      width: calc(100% - 20rem);
      text-align: left;
      padding: rem(0 20px 0 40px);
      @include media-breakpoint-down(md) {
        margin-top: 2rem;
        width: 100%;
        padding: 0;
        text-align: center;
      }
      .top-line{
        margin-bottom: 1.5rem;
        a{
          white-space: nowrap;
          color: cl(white);
          font-family: $fontJ;
          font-weight: 700;
          margin-right: 1rem;
          font-size:rem(13px);
          line-height: rem(16px);
          img{
            vertical-align: middle;
            height: rem(16px);
            margin-right: 0.3rem;
          }
        }
      }
      p{
        margin-bottom: 2rem;
      }
      .sub-ttl{
        color: #545454;
        font-size: rem(20px);
        line-height: rem(30px);
        font-family: $fontP;
        letter-spacing: 0.05em;
        margin-bottom: rem(14px);
        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }
      .ttl{
        position: relative;
        font-size: rem(20px);
        line-height: 1.2;
        margin: rem(0 0 20px);
        font-family: $fontJ;
        font-weight: 700;
        padding-bottom: rem(35px);
        &:before{
          content: "";
          position: absolute;
          width: rem(60px);
          height: rem(4px);
          left:0%;
          font-style: normal;
          bottom: 0;
          background-color: cl(primary);
          //animation: animation-line-2 6s infinite linear;
          @include media-breakpoint-down(md) {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .btns-wrap{
        margin-top:1rem;
        gap:1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        @include media-breakpoint-down(md) {
          justify-content: center;
        }
        .btn{

          @include media-breakpoint-down(xs) {
            width: 100%;
          }
        }

      }
    }
    .image-side{
      width:20rem;
      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 35.62rem;
        margin: 0 auto;
      }
      .picture{
        box-shadow: rem(0px 17px 70px 0px rgba(0, 0, 0, 0.3));
        max-width: 100%;
        max-height: rem(470px);
        width: 100%;
        object-fit: cover;
        object-position: top;
        @include media-breakpoint-down(md) {
          margin: 0;
          max-height: 80vw;
        }
      }
    }

  }
}
.posts-block-inner{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
  .left-side{
    flex-shrink: 0;
    width: calc(100% - 20rem);
    padding-right: 2rem;
    @include media-breakpoint-down(md) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 3rem;
    }
    ul{
      padding-left: 2rem;
      li{
        padding: 0.5rem 0;
      }
    }
    .separation-btns-wrap {
      border-top: 1px solid  cl(secondary);
      padding-top: 1.5625rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .social-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p{
          margin-bottom: 0;
          font-size: 1rem;
          line-height: 2.25rem;
          color: cl(text);
          font-weight: 700;
        }
        a {
          margin-left: 1.25rem;
          color: var(primary);
          font-size: 1.125rem;
          line-height: 2.25rem;
        }
      }
    }
    .top-line {
      margin-bottom: 1.5rem;
      a {
        white-space: nowrap;
        color: cl(text);
        font-family: $fontJ;
        font-weight: 700;
        margin-right: 1rem;
        font-size: rem(14px);
        line-height: rem(16px);

        img {
          vertical-align: middle;
          height: rem(16px);

          box-shadow: none;
          margin: 0;
          margin-right: 0.3rem;
        }
      }
    }
    p {
      margin-bottom: 2rem;
    }
    .sub-ttl {
      color: #545454;
      font-size: rem(20px);
      line-height: rem(30px);
      font-family: $fontP;
      letter-spacing: 0.05em;
      margin-bottom: rem(14px);
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
    .ttl {
      position: relative;
      font-size: rem(30px);
      line-height: 1.2;
      margin: rem(0 0 20px);
      font-family: $fontJ;
      font-weight: 700;
      padding-bottom: rem(35px);

      &:before {
        content: "";
        position: absolute;
        width: rem(60px);
        height: rem(4px);
        left: 0%;
        font-style: normal;
        bottom: 0;
        background-color: cl(primary);
      }
    }
    img {
      max-width: 100%;
      box-shadow: rem(0px 17px 70px 0px rgba(0, 0, 0, 0.3));
      margin: 0 auto 3rem;
    }
  }
  .right-side{
    flex-shrink: 0;
    width: 30rem;
    @include media-breakpoint-down(lg) {
      width: 20rem;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    .instagramm-posts{
      width: 100%;
      margin-top: 3rem;
      .ttl-wrap{
        position: relative;
        margin: rem(0 0 20px);
        text-align: center;
        color: cl(text);
        padding-bottom: rem(22px);
        &:before {
          content: "";
          position: absolute;
          width: rem(60px);
          height: rem(4px);
          font-style: normal;
          bottom: 0;
          background-color: cl(primary);
          animation: animation-line-2 6s infinite linear;
          left: 50%;
          transform: translateX(-50%);
        }
        h2{
          margin-bottom: 0;
        }
      }
      .sub-ttl{
        color: #545454;
        font-size: rem(20px);
        line-height: rem(30px);
        font-family: $fontP;
        letter-spacing: 0.05em;
        text-align: center;
        margin-bottom: 0;
      }
      .insta-wrap{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
        flex-wrap: wrap;
        .item{
          width: calc(33.3% - 0.75rem);
          position: relative;
          padding-bottom: 30%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

      }
    }
    .contact--form-wrap{
      max-width: rem(600px);
      padding: 1.25rem;
      background-color: cl(secondary);
      position: relative;
      @include media-breakpoint-down(md) {
       margin: 0 auto;
      }
      .ttl-wrap{
        position: relative;
        margin: rem(0 0 20px);
        text-align: center;
        color: cl(white);
        padding-bottom: rem(22px);
        &:before {
          content: "";
          position: absolute;
          width: rem(60px);
          height: rem(4px);
          font-style: normal;
          bottom: 0;
          background-color: cl(primary);
          animation: animation-line-2 6s infinite linear;
          left: 50%;
          transform: translateX(-50%);
        }
        h2{
          margin-bottom: 0;
        }
      }
      .sub-ttl{
        color: #545454;
        font-size: rem(20px);
        line-height: rem(30px);
        font-family: $fontP;
        letter-spacing: 0.05em;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
}

.blog-block {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
  width: calc(100% - 25rem);
  flex-wrap: wrap;
  @include media-breakpoint-down(md) {
    position: relative;
    top: unset;
    right: unset;
    width: 100%;
  }
  .blog-item{
    width: calc(33% - 2rem);
    background-color: #494949;
    padding: rem(30px 20px);
   // width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: rem(30px);
    color: cl(white);
      flex-direction: column;
    @include media-breakpoint-down(lg) {
      width: calc(50% - 2rem);
    }
    @include media-breakpoint-down(md) {
      width: calc(33% - 2rem);
    }
    @include media-breakpoint-down(sm) {
      width: calc(50% - 2rem);
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
    .text-side{
        margin-top: 2rem;
        width: 100%;
        padding: 0;
       // text-align: center;

      .top-line{
        margin-bottom: 1.5rem;
        a{
          white-space: nowrap;
          color: cl(white);
          font-family: $fontJ;
          font-weight: 700;
          margin-right: 1rem;
          font-size:rem(13px);
          line-height: rem(16px);
          img{
            vertical-align: middle;
            height: rem(16px);
            margin-right: 0.3rem;
          }
        }
      }
      p{
        margin-bottom: 1rem;
        max-height: 3rem;
        overflow: hidden;
      }
      .more-link{
        display: inline-block;
        color: cl(primary);
        font-size: rem(15px);
        line-height: rem(40px);
        font-family: $fontJ;
        font-weight: 700;
        @extend .ttu;
      }
      .sub-ttl{
        color: #545454;
        font-size: rem(20px);
        line-height: rem(30px);
        font-family: $fontP;
        letter-spacing: 0.05em;
        margin-bottom: rem(14px);
        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }
      .ttl{
        position: relative;
        font-size: rem(20px);
        line-height: 1.2;
        margin: rem(0 0 20px);
        font-family: $fontJ;
        font-weight: 700;
        margin-bottom: 1rem;
        display: block;
      }
      .btns-wrap{
        margin-top:1rem;
        gap:1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        @include media-breakpoint-down(md) {
          justify-content: center;
        }
        .btn{

          @include media-breakpoint-down(xs) {
            width: 100%;
          }
        }

      }
    }
    .image-side{
        width: 100%;
        max-width: 35.62rem;
        margin: 0 auto;
      .picture{
        box-shadow: rem(0px 17px 70px 0px rgba(0, 0, 0, 0.3));
        max-width: 100%;
        max-height: rem(470px);
        width: 100%;
        object-fit: cover;
        object-position: top;
        @include media-breakpoint-down(md) {
          margin: 0;
          max-height: 80vw;
        }
      }
    }

  }
}