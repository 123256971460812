@font-face {
  font-family: 'Trajan Pro 3';
  src: url('../fonts/TrajanPro3Regular.eot');
  src: url('../fonts/TrajanPro3Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TrajanPro3Regular.woff2') format('woff2'),
  url('../fonts/TrajanPro3Regular.woff') format('woff'),
  url('../fonts/TrajanPro3Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

