.contact-page{
  .contact-cards-section{
    position: relative;
    min-height: rem(200px);
    padding-bottom: rem(40px);
    &:before{
      content:"";
      @extend .pos-centerX;
      top: 0;
      width: 100%;
      height: rem(160px);
      background-color: cl(brown-light);
    }
    .container{
      max-width: rem(1270px);
    }
    .contact-cards-grid{
      display: flex;
      justify-content: center;
      align-items: stretch;
      gap:1rem;
      flex-wrap: wrap;
      .item{
        width: calc(33.33% - 0.75rem);
        background-color: #0a0a0a;
        color: #c6c6c6;
        font-size: rem(15px);
        line-height: rem(25px);
        padding: rem(40px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.3);
        @include media-breakpoint-down(md) {
          width: calc(50% - 0.5rem);
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
        &:nth-child(even){
          background-color: #242424;
        }
        .item-inner{
          //min-height: rem(190px);
          flex-grow: 1;
          @include media-breakpoint-down(sm) {
            min-height: rem(190px);
          }
          @include media-breakpoint-down(xs) {
            min-height: rem(240px);
          }
        }
        .schedule-list{
          width: 100%;
          ul{
            list-style: none;
            width: 100%;
            li{
              color: cl(white);
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: rem(15px);
              line-height: rem(26px);
              padding: rem(7px 0);
              border-bottom: 1px solid rgba(255, 255, 255, 0.239);
              &:last-child{
                border: 0;
              }
            }
          }
        }
        .tel{
          display: block;
          color: cl(white);
          margin-bottom: 0.5rem;
          font-size: rem(20px);
          i{
            animation: animation-pulse-1 4s infinite;
          }
        }
        img{
          height: 1.5rem;
          margin-bottom: rem(20px);
        }
        .ttl{
          color: cl(white);
          @extend .ttu;
          font-family: $fontJ;
          font-weight: 700;
          font-size: rem(16px);
          line-height: rem(25px);
          margin-bottom: rem(14px);
        }
        .line{
          background-color: rgb(255, 255, 255);
          width: 62%;
          height: 1px;
          margin-bottom: 1.25rem;
        }
      }
    }
  }
  .contact--form-wrap{
    max-width: rem(600px);
    padding: 0 1.25rem;
    background-color: cl(white);
    position: relative;
    margin: 2rem auto 0;
    .ttl-wrap{
      position: relative;
      margin: rem(0 0 20px);
      text-align: center;
      padding-bottom: rem(22px);
      &:before {
        content: "";
        position: absolute;
        width: rem(60px);
        height: rem(4px);
        font-style: normal;
        bottom: 0;
        background-color: cl(primary);
        animation: animation-line-2 6s infinite linear;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .sub-ttl{
      color: #545454;
      font-size: rem(20px);
      line-height: rem(30px);
      font-family: $fontP;
      letter-spacing: 0.05em;
      text-align: center;
      margin-bottom: 0;
    }
  }
  .contacts-block{
    max-width: rem(770px);
    margin: 3rem auto 3rem;
    @include media-breakpoint-down(sm) {
      margin: 3rem auto 2rem;
    }
    .line{
      width: 1px;
      height: 100%;
      position: absolute;
      left: 0;
      background-color: rgb(221, 221, 221);
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    address{
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }
    .contacts {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: rem(16px);
      line-height: rem(26px);
      margin-bottom: 0;

      dt {
        color:cl(black);
        font-size: rem(16px);
        line-height: rem(26px);
        width:rem(36px);
        img{
          width: 100%;
          margin-bottom: 1rem;
        }
      }
      dd {
        margin: 0;
        a {
          color:cl(black);
        }
      }
    }
  }
}
.analysis-page{
  .analysis-cards-section{
    position: relative;
    min-height: rem(200px);
    padding-bottom: rem(40px);
    &:before{
      content:"";
      @extend .pos-centerX;
      top: 0;
      width: 100%;
      height: rem(160px);
      background-color: cl(brown-light);
    }
    //.container{
    //  max-width: rem(1270px);
    //}

    .analysis--grid{
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      gap:1rem;
      flex-wrap: wrap;
      .item{
        margin-bottom: rem(125px);
        width: calc(50% - 7vw);
        background-color: #0a0a0a;
        color: #c6c6c6;
        font-size: rem(15px);
        line-height: rem(25px);
        padding: rem(40px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.3);
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-bottom: 2rem;
        }
        &:nth-child(even){
          background-color: #242424;
        }
        .item-inner{
          //min-height: rem(190px);
          flex-grow: 1;
          @include media-breakpoint-down(sm) {
            min-height: rem(190px);
          }
          @include media-breakpoint-down(xs) {
            min-height: rem(240px);
          }
        }
        .schedule-list{
          width: 100%;
          ul{
            list-style: none;
            width: 100%;
            li{
              color: cl(white);
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: rem(15px);
              line-height: rem(26px);
              padding: rem(7px 0);
              border-bottom: 1px solid rgba(255, 255, 255, 0.239);
              &:last-child{
                border: 0;
              }
            }
          }
        }
        .tel{
          display: block;
          color: cl(primary);
          font-size: rem(22px);
          font-family: $fontJ;
          font-weight: 700;
          margin: 0 auto 2.75rem;
          text-align: center;
          i{
            animation: animation-pulse-1 4s infinite;
          }
        }
        img{
          height: 1.5rem;
          margin-bottom: rem(20px);
        }
        .ttl{
          color: cl(white);
          @extend .ttu;
          font-family: $fontJ;
          font-weight: 700;
          font-size: rem(16px);
          line-height: rem(25px);
          margin-bottom: rem(14px);
        }
        .line{
          background-color: rgb(255, 255, 255);
          width: 62%;
          height: 1px;
          margin-bottom: 1.25rem;
        }
      }
      .form-wrap{
        width: 50%;
        padding: 0 0 0 2rem;
        position: relative;
        margin: rem(200px 0 0 0);
        @include media-breakpoint-down(sm) {
          width: 100%;
          padding: 1rem;
          margin: rem(0px 0 0 0);
        }
        .ttl-wrap{
          position: relative;
          margin: rem(0 0 20px);
          text-align: center;
          padding-bottom: rem(22px);
          &:before {
            content: "";
            position: absolute;
            width: rem(60px);
            height: rem(4px);
            font-style: normal;
            bottom: 0;
            background-color: cl(primary);
            animation: animation-line-2 6s infinite linear;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .sub-ttl{
          color: #545454;
          font-size: rem(20px);
          line-height: rem(30px);
          font-family: $fontP;
          letter-spacing: 0.05em;
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
  }
  .marque-wrap{
    margin-top: -4.5vw;
    overflow: hidden;
    .marquee-honor{
      b{
        padding-right: 10vw;
        font-size: 10vw;
        color: cl(white);
        line-height: 13vw;
        font-family: $fontJ;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        text-shadow: rem(-6.101px 13.703px 43px rgba(0, 0, 0, 0.11));
        vertical-align: middle;
        letter-spacing: 0.3em;
        img{
          padding-right: 2vw;
          height: 12vw;
          vertical-align: sub;
        }
      }
    }
  }
}
