.btn {
  color:cl(white)!important;
  font-size: rem(16px);
  font-family: $fontJ;
  font-weight: 700;
  line-height: rem(48px);
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  border: 1px solid cl(primary);
  background-color: cl(primary);
  cursor: pointer;
  border-radius: rem(2px 2px 2px 2px);
  padding: rem(0 25px);
  letter-spacing: 0.05em;
  outline: 0;
  white-space: nowrap;
  transition: 0.5s ease-in-out;
  min-width: rem(150px);
  z-index: 3;
  position: relative;
  text-transform: uppercase;
  @include media-breakpoint-down(sm) {
    white-space: normal;
    width: auto;
    max-width: 100%;
  }
  &:hover,
  &:focus{
    color: cl(white) !important;
    background-color: cl(primary, 0.7);
    border: 1px solid cl(primary);
  }
  &:focus {
    box-shadow:none;
    outline: none;
  }
  &--link{
    border:0;
    background:none;
    color:cl(black)!important;
    padding: 0 0.25rem;
    min-width: unset;
    &:hover,
    &:focus{
      border:0;
      background:none;

      color:cl(black)!important;
    }
  }
  &--link-white{
    border:0;
    background:none;
    color:cl(white)!important;
    padding: 0 0.25rem;
    min-width: unset;
    &:hover,
    &:focus{
      border:0;
      background:none;

      color:cl(white)!important;
    }
  }
  &--color-black{
    color:cl(black)!important;
  }
  &--white{
    border-color: cl(white);
    background-color:cl(white);
    color:cl(black)!important;
    &:hover,
    &:focus{
      border-color: cl(white);
      background-color:cl(white,0.7);
      color:cl(black)!important;
    }
  }
  &--black{
    border-color: cl(black);
    background-color:cl(black);
    color:cl(white)!important;
    &:hover,
    &:focus{
      border-color: cl(black);
      background-color:cl(black,0.7);
      color:cl(white)!important;
    }
  }
  &--white-border-black{
    border-color: cl(black);
    background-color:cl(white);
    color:cl(black)!important;
    &:hover,
    &:focus{
      border-color: cl(black);
      background-color:cl(white,0.7);
      color:cl(black)!important;
    }
  }
  &--outline-primary{
    border-color: cl(primary)!important;
    color:cl(primary) !important;
    background-color:transparent;
    &:hover,
    &:focus{
      color:cl(primary) !important;
      background-color:cl(primary, 0.2);
    }
  }
  &--outline-black{
    border-color: cl(black)!important;
    color:cl(black) !important;
    background-color:cl(white);
    &:hover,
    &:focus{
      color:cl(black) !important;
      background-color:cl(black, 0.2);
    }
  }
  &--outline-white{
    border-color: cl(white)!important;
    color:cl(white) !important;
    background-color:transparent;
    &:hover,
    &:focus{
      color:cl(white) !important;
      background-color:cl(white, 0.2);
    }
  }
  &--border-outline-white{
    &:before{
      position: absolute;
      content: "";
      border: 1px solid cl(white);
      width: calc(100% + 0.625rem);
      height: calc(100% + 0.625rem);
      @extend .pos-center;
    }
  }
  //&--outline-secondary{
  //  border-color: cl(secondary)!important;
  //  background-color:transparent;
  //      color:cl(secondary)!important;
  //  &:hover,
  //  &:focus{
  //    color:cl(secondary)!important;
  //    background-color:cl(secondary, 0.7);
  //  }
  //}
  &--small{
    font-weight: 400;
    font-size: rem(16px);
    letter-spacing: 0;
    i{
      font-size: rem(12px);
    }
  }
}




.floating-btn {
  top: 50%;
  right: rem(-65px);
  transform: translateY(-50%) rotate(-90deg);
  position: fixed;
  border-radius: 1.25rem 1.25rem 0 0;
  z-index: 100;
  color: cl(primary);
  font-size: rem(18px);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  box-shadow: 0.942px -11.984px 54px 0px rgba(0, 0, 0, 0.21);
  background-color: cl(white);
  font-weight: 600;
  width: rem(170px);
  height: rem(40px);
  transition: all 0.2s linear;
  @include media-breakpoint-down(lg){
    display: none;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    padding: 0;
    right: 0.5rem;
    bottom: 1.5rem;
    top: unset;
    transform: none;
    background-color: cl(primary);
    box-shadow: 0.837px 11.971px 54px 0px rgba(0, 0, 0, 0.21);
  }
  &:hover{
    text-decoration: none;
    color: cl(white);
    background-color: cl(primary);
  }
  span{
    @include media-breakpoint-down(lg){
      display: none;
    }
  }
  .mobile-icon{
    display: none;
    @include media-breakpoint-down(lg){
      display: block;
      width: 60%;
      object-fit: contain;
    }

  }
}