.community--section{
  padding: rem(40px 0 70px 0);
  position: relative;
  z-index: 2;
  background-color: cl(white);
  font-size: rem(16px);
  line-height: 1.6;
  .text-center{
    max-width: rem(900px);
    margin: 0 auto;
  }
  .options--grid{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2.6rem;
    flex-wrap: wrap;
    p{
      margin-bottom: 0;
    }
    .options__item{
      width: calc(33% - 2rem);
      font-size:rem(15px);
      @include media-breakpoint-down(md) {
        width: calc(50% - 2rem);
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
      &:hover{
        .option__item-image-wrap{
          .image{
            transform: scale(1);
          }
        }
      }
      .option__item-image-wrap{
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-bottom: 94%;
        .image {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          transform: scale(1.05);
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          display: block;
          transition: all 0.3s ease-in-out;
          &:focus{
            transform: scale(1);
          }
        }
      }
      .option__item-text{
        position: relative;
        margin:rem(-55px auto 1rem);
        background-color: rgb(255, 255, 255);
        box-shadow: -6.553px 4.589px 50px 0px rgba(0, 0, 0, 0.33);
        width: 74%;
        font-size: rem(20px);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: rem(90px);
        padding: rem(15px 10px);
        .name{
         text-transform: none;
          font-size: rem(16px);
          font-weight: 700;

        }
        .benefit{
          color: cl(primary);
        }
      }
    }
    .link{
      color: cl(black);
      @extend .ttu;
      display: block;
      margin-top: 0.75rem;
    }
  }
}