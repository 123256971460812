.photos-section{
  padding: rem(60px 0);
}
//.photo-gallery-inner{
//  display: flex;
//  column-gap: 2rem;
//  flex-wrap: wrap;
//  .item{
//    display: block;
//    width: calc(33% - 1.2rem);
//
//    font-size: rem(16px);
//    line-height: rem(26px);
//    color: cl(text);
//    margin-bottom: 1.75rem;
//    cursor: pointer;
//    position: relative;
//    padding-bottom:21%;
//    @include media-breakpoint-down(sm) {
//      width: calc(50% - 1.2rem);
//      padding-bottom:31%;
//    }
//    @include media-breakpoint-down(xs) {
//      width: 100%;
//      padding-bottom:67%;
//    }
//    &:hover{
//      box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.3);
//      color: cl(secondary);
//      text-decoration: none;
//
//    }
//    img {
//      display: block;
//      max-width: 100%;
//      width: 100%;
//      height: 100%;
//      position: absolute;
//      left: 0;
//      top: 0;
//      object-fit: cover;
//      will-change: auto;
//    }
//  }
//}

.lg-thumb.lg-group{
  margin-left: auto;
  margin-right: auto;
}
.lg-outer .lg-image {
  object-fit: contain;
  object-position: center;
  max-height: 70vh!important;
}

.photo-gallery-inner{
  min-height: rem(600px);
  /* clear fix */
  .grid:after {
    content: '';
    display: block;
    clear: both;
  }
  .grid-sizer,
  .grid-item {
    width: calc(33% - 29px);
    @include media-breakpoint-down(sm) {
      width: calc(50% - 29px);
    }
    @include media-breakpoint-down(xs) {
      width: calc(50% - 16px);
    }
  }
  .grid-item {
    float: left;
    margin-bottom: 2rem;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-shadow: rem(0.803px 22.986px 43px 0px rgba(0, 0, 0, 0.23));
    overflow: hidden;
    &:hover{
      cursor: pointer;

    }
    .play-btn{
      @extend .pos-center;
      width: rem(60px);
      height: rem(60px);
      border-radius: 50%;
    }
    img {
      display: block;
      max-width: 100%;
      width: 100%;
      will-change: auto;
    }
  }

}
.links-block-wrap{
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  flex-wrap: wrap;
  .link-to-inner{
    width: calc(33.33% - 1.41rem);
    font-size: rem(18px);
    line-height: rem(28px);
    @include media-breakpoint-down(md) {
      width: calc(50% - 1.2rem);
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
    &:hover{
      text-decoration: none;
      .ttl{
        color: cl(primary);
      }
      .image{
        box-shadow: none;
      }
    }
    .image{
      box-shadow: rem(0.803px 22.986px 43px 0px rgba(0, 0, 0, 0.23));
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding-bottom: 56%;
    }
    .ttl{
      padding-top: 1rem;
      display: block;
      color: cl(black);
      font-weight: 700;
    }
  }

}